<template>
  <div>
    <nav class="navbar navbar-dark bg-dark navbar-expand-md">
      <div class="container-fluid d-flex align-items-center">
        <a class="navbar-brand" href="#">
          <div class="d-flex">
            <img
              src="@/assets/header/logos/paysmart.png"
              class="paysmart-logo"
              alt="paysmart"
            />
            <div
              class="d-flex align-items-center justify-content-between"
              :class="[
                $i18n.locale === 'fr' || $i18n.locale === 'en'
                  ? 'all-rep'
                  : 'all-rep-ar',
              ]"
            >
              <span class="republique d-flex flex-column">
                <span>{{ $t("republique1") }}</span>
                <span>{{ $t("republique2") }}</span>
              </span>
              <img
                src="@/assets/header/logos/TN.png"
                height="28"
                alt="TN"
                class="paysmart-tn"
              />
            </div>
          </div>
        </a>
        <ul
          class="navbar-nav d-flex align-items-center justify-content-between"
        >
          <li class="text-white aux-bar">
            <a
              href="#discover-paysmart"
              class="text-decoration-none text-white link-active"
              >{{ $t("nav.Paysmart") }}</a
            >
          </li>
          <li class="text-white aux-bar">-</li>
          <li class="text-white aux-bar">
            <a
              href="#our-services"
              class="text-decoration-none text-white link-active"
              >{{ $t("nav.service") }}</a
            >
          </li>
          <!-- <li class="text-white aux-bar">-</li>
          <li class="text-white aux-bar">
            <a
              href="#videoteque"
              class="text-decoration-none text-white link-active"
              >videoteque</a
            >
          </li>
          <li class="text-white aux-bar">-</li>
          <li class="text-white aux-bar">
            <a
              href="#faq"
              class="text-decoration-none text-white link-active"
              >FAQ</a
            >
          </li> -->
          <li class="text-white aux-bar">-</li>
          <li class="text-white aux-bar">
            <a
              href="#partners"
              class="text-decoration-none text-white link-active"
              >{{ $t("nav.reference") }}</a
            >
          </li>
          <li class="text-white aux-bar">-</li>
          <li class="text-white aux-bar">
            <a
              href="#reclamation"
              class="text-decoration-none text-white link-active"
              >{{ $t("nav.reclamation") }}</a
            >
          </li>
          <li class="text-white aux-sm-bar">
            <span
              @click="goToPro()"
              class="btn btn-outline-white my-space me-1"
            >
              <img
                width="12"
                src="@/assets/header/logos/mon-espace.png"
                alt="my space"
              />
              {{ $t("nav.espace") }}
            </span>
          </li>
          <li class="text-white aux-sm-bar">
            <div @click.stop="dialog = true">
              <notification-bell
                :icon="image"
                :size="30"
                :count="getTotal()"
                :ding="true"
                :upperLimit="99"
                counterLocation="upperRight"
                counterStyle="roundRectangle"
                counterBackgroundColor="#FF0000"
                counterTextColor="#FFFFFF"
                iconColor="#000000"
                :animated="true"
                :prefixPlus="true"
                class="notif-dir"
              />
            </div>
          </li>
          <li class="text-white aux-sm-bar">
            <b-nav-item-dropdown
              class="custom-dropdown-menu text-white"
              :class="[
                $i18n.locale === 'fr' || $i18n.locale === 'en'
                  ? 'float-left'
                  : 'float-right',
              ]"
            >
              <template slot="button-content" class="text-white">
                {{
                  $i18n.locale == "fr"
                    ? "Fr"
                    : $i18n.locale == "ar"
                    ? "ع"
                    : $i18n.locale == "en"
                    ? "En"
                    : $i18n.locale == "tn"
                    ? "ت"
                    : "Fr"
                }}
              </template>
              <b-dropdown-item
                ><button @click="setLocale('fr')">
                  Français
                </button></b-dropdown-item
              >
              <b-dropdown-item
                ><button @click="setLocale('en')">
                  English
                </button></b-dropdown-item
              >

              <b-dropdown-item
                ><button @click="setLocale('ar')">عربي</button></b-dropdown-item
              >
              <b-dropdown-item
                ><button @click="setLocale('tn')">
                  تونسي
                </button></b-dropdown-item
              >
            </b-nav-item-dropdown>
          </li>
          <li class="text-white">
            <img
              src="@/assets/header/logos/mognetique.png"
              alt="mongnetique-logo"
              class="paysmart-mongnetique"
            />
          </li>
        </ul>
      </div>
    </nav>
    <img
      src="@/assets/all_new_images/burger-blue.png"
      class="burger p-2 position-fixed"
      :class="[
        $i18n.locale === 'fr' || $i18n.locale === 'en'
          ? 'start-0'
          : 'start-100 end-0',
      ]"
      @click="showNowBurger"
      alt="burger"
    />
    <ul
      class="d-none"
      :class="[
        showBurger && 'show-burger d-flex flex-column align-items-start p-4',
      ]"
    >
      <img
        src="@/assets/all_new_images/burger.png"
        class="burger"
        @click="showNowBurger"
        alt="burger-2"
      />
      <li class="text-white" @click="showNowBurger">
        <a
          href="#discover-paysmart"
          class="text-decoration-none text-white link-active"
          >{{ $t("nav.Paysmart") }}</a
        >
      </li>
      <li class="text-white" @click="showNowBurger">
        <a
          href="#our-services"
          class="text-decoration-none text-white link-active"
          >{{ $t("nav.service") }}</a
        >
      </li>
      <!-- <li class="text-white" @click="showNowBurger">
            <a
              href="#videoteque"
              class="text-decoration-none text-white link-active"
              >videoteque</a
            >
          </li>
          <li class="text-white" @click="showNowBurger">
            <a
              href="#faq"
              class="text-decoration-none text-white link-active"
              >FAQ</a
            >
          </li> -->
      <li class="text-white" @click="showNowBurger">
        <a
          href="#partners"
          class="text-decoration-none text-white link-active"
          >{{ $t("nav.reference") }}</a
        >
      </li>
      <li class="text-white" @click="showNowBurger">
        <a
          href="#reclamation"
          class="text-decoration-none text-white link-active"
          >{{ $t("nav.reclamation") }}</a
        >
      </li>
      <li class="text-white aux-sm-bar-show" @click="showNowBurger">
        <span @click="goToPro()" class="btn btn-outline-white my-space me-1">
          <img
            width="12"
            src="@/assets/header/logos/mon-espace.png"
            alt="my space-2"
          />
          {{ $t("nav.espace") }}
        </span>
      </li>
      <li class="text-white aux-sm-bar-show" @click="showNowBurger">
        <div @click.stop="dialog = true">
          <notification-bell
            dir="ltr"
            :icon="image"
            :size="30"
            :count="getTotal()"
            :ding="true"
            :upperLimit="99"
            counterLocation="upperRight"
            counterStyle="roundRectangle"
            counterBackgroundColor="#FF0000"
            counterTextColor="#FFFFFF"
            iconColor="#000000"
            :animated="true"
            :prefixPlus="true"
          />
        </div>
      </li>
      <li class="text-white aux-sm-bar-show">
        <b-nav-item-dropdown class="custom-dropdown-menu text-white" left>
          <template slot="button-content" class="text-white">
            {{
              $i18n.locale == "fr"
                ? "Fr"
                : $i18n.locale == "ar"
                ? "ع"
                : $i18n.locale == "en"
                ? "En"
                : $i18n.locale == "tn"
                ? "ت"
                : "Fr"
            }}
          </template>
          <b-dropdown-item
            ><button
              @click="
                setLocale('fr');
                showNowBurger();
              "
            >
              Français
            </button></b-dropdown-item
          >
          <b-dropdown-item
            ><button
              @click="
                setLocale('en');
                showNowBurger();
              "
            >
              English
            </button></b-dropdown-item
          >

          <b-dropdown-item
            ><button
              @click="
                setLocale('ar');
                showNowBurger();
              "
            >
              عربي
            </button></b-dropdown-item
          >
          <b-dropdown-item
            ><button
              @click="
                setLocale('tn');
                showNowBurger();
              "
            >
              تونسي
            </button></b-dropdown-item
          >
        </b-nav-item-dropdown>
      </li>
    </ul>
    <section id="discover-paysmart" class="position-relative">
      <div
      class="inherit-height-1 main-banner-box"        :class="[
          $i18n.locale === 'fr' || $i18n.locale === 'en'
            ? 'main-banner-box'
            : 'main-banner-box-ar',
        ]"
        style  = "position:relative"
      >
        <!-- <div
      
        class="container"> -->
          <!-- <div 
            class="d-flex my-4 section1-btns"
            style="gap: 15px"
          > -->
            <!-- <div
            class="position-relative section1-btns-part1"
            :class="[
              $i18n.locale === 'fr' || $i18n.locale === 'en' ? 'me-4' : 'ms-4',
            ]"
            style="border-radius: 5px"
          >
          <div>
            <input
            style="padding: 10px"
              class="pay-inputs"
              :class="[invoiceCnte && !notValidRefCnte && 'border border-danger']"
              id="invoiceCnte"
              type="text"
              v-model="invoiceCnte"
              :placeholder="$t('modalConsultation.idUnique') "
              @input="validInputCnte()"
            />
            <span
              v-if="invoiceCnte && !notValidRefCnte"
              class="text-danger position-absolute top-ref"
              :class="[
                $i18n.locale === 'fr' || $i18n.locale === 'en'
                  ? 'start-0'
                  : 'end-0',
              ]"
              style="font-size: 12px"
              >{{ errorRefCnte }}</span
            >
          </div>
          <vue-recaptcha
            :class="[
              $i18n.locale === 'fr' || $i18n.locale === 'en' ? 'me-4' : 'ms-4',
            ]"
            class="small-captcha"
            ref="recaptcha3"
            @expired="expiredcaptcha3"
            @verify="onVerifyCnte"
            :sitekey="idCaptcha3"
          >
          </vue-recaptcha>
         </div> -->
          <!-- <div
            :class="[
              $i18n.locale === 'fr' || $i18n.locale === 'en' ? 'me-4' : 'ms-4',
            ]"
          >
        
            <button
              class="btn-find"
              :class="[captcha3 === false && 'disable']"
              :disabled="captcha3 === false"
              @click="goToDetailCnte()"
            >
              <img
                src="@/assets/all_new_images/search.png"
                width="18"
                class="scale"
                alt="search"
              />
               {{ $t("section1.inscri") }}       </button>
              </div> -->
   
          <!-- </div> -->
          <div
            class="d-flex flex-column align-items-center playstore-apple " :class="[
          $i18n.locale === 'fr' || $i18n.locale === 'en'
            ? 'new-design'
            : 'new-design-ar',
        ]"
          style = "
            position: absolute;
            bottom: 24%;
            left: 50%;
            transform: translateX(-50%)"
          >
            <span
              style="
                font-size: 15px;
                letter-spacing: 0px;
                line-height: 19px;
                font-weight: 600 !important;
                text-decoration: none;
                color: white;
                font-family: 'Helvetica';
              "
              class="  d-flex align-items-center p-0 text-application"
            >
              {{ $t("section1.télécharger_appli") }}
            </span>
            <ul
              class="d-flex align-items-center p-0 down-btn application-btn"
              style="gap: 10px; margin-top: 10px"
            >
              <li>
                <a
                  :href="apple_store"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="@/assets/img_part_1/playstore.png"
                    style="width: 145px  ; height: 45px;"
                    alt="da"
                    class="scale application"
                /></a>
              </li>
              <li>
                <a
                  :href="google_play"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="@/assets/img_part_1/app-store.png"
                    alt="dg"
                    class="scale application"
                    style="width: 145px  ; height: 45px;"

                /></a>
              </li>
            </ul>
          </div>
        <!-- </div> -->
      </div>
   <!-- <div class="part-img-design"></div> -->
      <div class="container inherit-height-cnte" style="height: 1100px">
        <!-- <div style="height: 90px" class="pay-separation"></div> -->
    
        <div id="add-new-bill" class="d-flex flex-column justify-content-center">
        <div class="scrolling-container" style="color: #41b16d; fill: #41b16d">
          <img
            src="@/assets/all_new_images/loudspeaker.png"
            alt="lds"
            style="width: 30px"
          />
          <span class="scrolling-text fw-bold">
            {{ $t("section1.new_inscri") }}
          </span>
        </div>
        <h4>
          {{ $t("section1.payerText") }}
        </h4>
        <div class="d-flex align-items-center flex-wrap my-4 facts">
          <div
            @click="open2('sonde')"
            class="fact"
            :class="[isSonede && !addAccount && !addAccountTopnet && 'type-active']"
          >
            <img src="@/assets/all_new_images/sonede.png" alt="sonede" />
            <span class="pay-p"> Sonede </span>
          </div>
          <div
            @click="open2('steg')"
            class="fact"
            :class="[isSteg && !addAccount && !addAccountTopnet && 'type-active']"
          >
            <img src="@/assets/all_new_images/steg.png" />
            <span class="pay-p"> Steg </span>
          </div>
    
          <div
            @click="
              isSta = true;
              addAccount = true;
             addAccountTopnet =false
              resetCapt();
              isSonede = false;
              isSteg = false;
              isCnte = false;
              isTopnet = false
            "
            class="fact"
            :class="[isSta && addAccount && !addAccountTopnet && 'type-active']"
          >
            <img src="@/assets/all_new_images/sta.png" alt="sta" width="150" />
            <!-- <span class="pay-p"> STA </span> -->
          </div>
          <div
            @click="open2('cnte')"
            class="fact"
            :class="[isCnte && !addAccount && !addAccountTopnet && 'type-active']"
          >
            <img
              style="width: 80px; height: 46px"
              src="@/assets/images/partner/CNTE.png"
            />
            <span style="line-height: 18px !important;font-size: 17px;" class="pay-p"> {{ $t("modalDetail.inscri") }} </span>
          </div>
          <div
            @click="
              isTopnet = true;
              addAccountTopnet = true;
             addAccount =false
             openTopnet();
              isSonede = false;
              isSteg = false;
              isCnte = false;
              isSta = false;
            "
            class="fact"
            :class="[isTopnet && addAccountTopnet &&  !addAccount && 'type-active']"
          >
            <img src="@/assets/images/TOPNET.png" alt="topnet" height="50"  width="150" />
            <!-- <span class="pay-p"> STA </span> -->
          </div>
        </div>
        <div class="d-flex align-items-center find-bills-fields">
          <div
            :class="[
              $i18n.locale === 'fr' || $i18n.locale === 'en' ? 'me-4' : 'ms-4',
            ]"
            style="border-radius: 5px"
          >
            <!-- <select
              v-if="isSta"
              v-model="search"
              id="sonede-select"
              class="form-select pay-inputs"
              aria-label="Default select example"
              @change="getSelectType($event)"
            >
              <option value="clientPhone" selected>
                {{ $t('modalConsultation.clientPhone') }}
              </option>
              <option value="clientCin">
                {{ $t('modalConsultation.clientCin') }}
              </option>
              <option value="clientMf">
                {{ $t('modalConsultation.clientMf') }}
              </option>
              <option value="clientCode">
                {{ $t('modalConsultation.RefAbn') }}
              </option>
            </select> -->
          </div>
          <div
            class="position-relative"
            :class="[
              $i18n.locale === 'fr' || $i18n.locale === 'en' ? 'me-4' : 'ms-4',
            ]"
            style="border-radius: 5px"
          >
            <input
              class="pay-inputs"
              :class="[invoiceRef && !notValidRef && 'border border-danger']"
              id="invoiceRef"
              type="text"
              v-model="invoiceRef"
              :placeholder="
                search === 'contractNumber'
                  ? $t('modalConsultation.contract')
                  : isSteg
                  ? $t('modalConsultation.RefAbn')
                  : isCnte
                  ? $t('modalConsultation.idUnique')
                  : $t('modalConsultation.referenceF')
              "
              @input="validInput()"
            />
            <span
              v-if="invoiceRef && !notValidRef"
              class="text-danger position-absolute top-ref"
              :class="[
                $i18n.locale === 'fr' || $i18n.locale === 'en'
                  ? 'start-0'
                  : 'end-0',
              ]"
              style="font-size: 12px"
              >{{ errorRef }}</span
            >
          </div>
          <vue-recaptcha
            :class="[
              $i18n.locale === 'fr' || $i18n.locale === 'en' ? 'me-4' : 'ms-4',
            ]"
            class="small-captcha"
            ref="recaptcha"
            @expired="expiredCaptcha"
            @verify="onVerify"
            :sitekey="idCaptcha"
          >
          </vue-recaptcha>
          <div
            :class="[
              $i18n.locale === 'fr' || $i18n.locale === 'en' ? 'me-4' : 'ms-4',
            ]"
          >
            <!-- <button
            v-if="isSta"
              class="btn-find"
              :class="[captcha === false && 'disable']"
              :disabled="captcha === false"
              @click="goToDetail()"
            >
              {{  $t('modalConsultation.addAccount') }}
            </button> -->
            <button
              class="btn-find"
              :class="[captcha === false && 'disable']"
              :disabled="captcha === false"
              @click="goToDetail()"
            >
              <img
                src="@/assets/all_new_images/search.png"
                width="18"
                class="scale"
                alt="search"
              />
              {{ $t("section1.rechercher_fact") }}
            </button>
          </div>
        </div>
      </div>
        <h3>
          {{ $t("section1.titre1") }}
        </h3>
        <div class="row justify-content-center">
          <div
            class="col-md-4 d-flex flex-column align-items-center justify-content-center m-3 pay-step-box"
            style="gap: 15px"
          >
            <img
              src="@/assets/all_new_images/user.png"
              alt="user"
              class="scale"
            />
            <p
              style="
                font-size: 24px;
                color: #4c565d;
                font-weight: 700;
                font-family: 'Source Sans Pro';
                text-align: center;
              "
              class="pay-p"
            >
              {{ $t("section1.seul_compte") }}
            </p>
          </div>
          <div
            class="col-md-4 d-flex flex-column align-items-center justify-content-center m-3 pay-step-box"
            style="gap: 15px"
          >
            <img
              src="@/assets/all_new_images/wallet.png"
              alt="wl"
              class="scale"
            />
            <p
              style="
                font-size: 24px;
                color: #4c565d;
                font-weight: 700;
                font-family: 'Source Sans Pro';
                text-align: center;
              "
              class="pay-p"
            >
              {{ $t("section1.payer_par_carte") }}
            </p>
          </div>
        </div>
        <div
          class="d-flex flex-column align-items-center account-btn"
          style="gap: 15px; margin-top: 30px"
        >
          <button @click="createAccount()" class="btn-turquoise">
            {{ $t("section1.compte") }}
          </button>
          <span class="pay-p">{{ $t("section1.ou") }}</span>
          <button
            style="z-index: 4"
            @click="login()"
            class="btn-turquoise-outline"
          >
            {{ $t("section1.connexion") }}
          </button>
        </div>
      </div>
    </section>
    <v-dialog
      v-model="dialog"
      width="85%"
      overlay-color="white"
      overlay-opacity="0.5"
    >
      <v-card>
        <div class="modal-title">
          <div
            style="
              font-size: 34px;
              color: #337fa8;
              font-weight: 700;
              font-family: 'Source Sans Pro';
              text-align: center;
            "
            class="small-header"
          >
            {{ $t("modalPanier.panier") }}
          </div>
          <img
            @click="closeList()"
            class="close-modal-icon scale"
            src="@/assets/header/logos/plus-copy.png"
          />
        </div>
        <div
          class="d-flex align-items-center justify-content-start"
          style="margin: 0px 23px"
        >
          <button
            :class="[
              isBill
                ? 'btn-turquoise btn-panier'
                : 'btn-turquoise-outline btn-panier-outline',
            ]"
            @click="selectService('isBill')"
          >
            {{ $t("modalPanier.facture") }}
          </button>
          <button
            :class="[
              isCharging
                ? 'btn-turquoise btn-panier'
                : 'btn-turquoise-outline btn-panier-outline',
            ]"
            @click="selectService('isCharging')"
          >
            {{ $t("modalPanier.recharge") }}
          </button>
          <button
            :class="[
              isServices
                ? 'btn-turquoise btn-panier'
                : 'btn-turquoise-outline btn-panier-outline',
            ]"
            @click="selectService('isServices')"
          >
            {{ $t("modalPanier.service") }}
          </button>
        </div>
        <v-card-text v-if="isBill">
          <v-data-table
            :headers="headers"
            :items="desserts ? desserts : []"
            :items-per-page="desserts ? 5 : 0"
            :no-data-text="$t('no-data')"
            :footer-props="{
              itemsPerPageText: $t('nbpages'),
              itemsPerPageAllText: $t('all_pages'),
              itemsPerPageOptions: [5, 10, 15, 20, 25, 50, 100, -1],
            }"
            :disable-sort="$vuetify.breakpoint.smAndDown"
            class="elevation-1 custom-invoices-list"
          >
           <template v-slot:item.invoiceExpiryDate="{ item }">
              <v-chip>
                {{ getDate(item?.invoiceExpiryDate)}}
              </v-chip>
            </template>
           <template v-slot:item.invoiceDate="{ item }">
              <v-chip>
                {{ getDate(item?.invoiceDate) }}
              </v-chip>
            </template>
            <template v-slot:item.invoiceAmount="{ item }">
              <v-chip>
                {{ getMontant(item?.invoiceAmount) }}
              </v-chip>
            </template>
            <template v-slot:item.billerRs="{ item }">
              {{ item?.billerRs }}
            </template> 
            <template v-slot:item.Actions="{ item }">
              <div
                class="d-flex align-items-center justify-content-center"
                style="gap: 15px"

              >
              <button
                  v-if="item?.paymentStatus == 'Available'"
                  @click="openDetail(item.id)"
                  class="btn-icon"
                  style="
                    font-weight: 900;
                    color: rgb(51, 127, 168);
                    font-size: 13px !important;
                  "
                >
                {{$t("modalPanier.pay")}}

                </button>
                <button
                :disabled="item?.paymentStatus == 'PAID' && item.paymentsList.length === 0"
                :title="(item?.paymentStatus == 'PAID' && item.paymentsList.length === 0) ? $t('modalPanier.Ailleurs')  : '' "
                v-else-if="
                    item?.paymentStatus == 'PAID' ||
                    item?.paymentStatus == 'HPAID'
                  "
                  @click="openDetail(item.id)"
                  class="btn-icon"
                  style="
                    font-weight: 900;
                    color: #9196a8;
                    font-size: 13px !important;
                  "
                >
                {{$t("modalPanier.btnVoirdc")}}
                </button>
                <img
                  @click="deleteOne(item)"
                  src="@/assets/header/logos/bin.png"
                  width="15"
                  style="cursor: pointer"
                />
              </div>
            </template>
            <template v-slot:item.paymentStatus="{ item }">
              <span
                class="btn"
                style="border-radius: 5px !important;
                padding: 0 10px !important;
                width: 100px;"
                v-bind:style="getColorEtat(item?.paymentStatus)"
              >
                {{ getNameEtat(item?.paymentStatus) }}
              </span>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-text v-else-if="isCharging">
          <v-data-table
            :headers="chargingHeaders"
            :items="chargingDesserts ? chargingDesserts : []"
            :items-per-page="chargingDesserts ? 5 : 0"
            :no-data-text="$t('no-data')"
            :footer-props="{
              itemsPerPageText: $t('nbpages'),
              itemsPerPageAllText: $t('all_pages'),
              itemsPerPageOptions: [5, 10, 15, 20, 25, 50, 100, -1],
            }"
            :disable-sort="$vuetify.breakpoint.smAndDown"
            class="elevation-1 custom-invoices-list"
          >
            <template v-slot:item.rechargeDate="{ item }">
              <v-chip>
                {{ getDate(item?.rechargeDate) }}
              </v-chip>
            </template>
            <template v-slot:item.rechargeRef="{ item }">
              <v-chip>
                {{ item?.rechargeRef }}
              </v-chip>
            </template>
            <template v-slot:item.rechargeAmount="{ item }">
              <v-chip>
                {{ getMontant(item?.rechargeAmount) }}
              </v-chip>
            </template>
            <template v-slot:item.billerRs="{ item }">
              {{ getServiceName(item?.billerRs) }}
            </template>
            <template v-slot:item.Actions="{ item }">
              <div
                class="d-flex align-items-center justify-content-center"
                style="gap: 15px"
              >
                <span
                  style="
                    cursor: pointer;
                    font-weight: 900;
                    color: rgb(51, 127, 168);
                    font-size: 13px !important;
                  "
                  @click="openCharging(item)"
                >
                  {{ $t("modalPanier.btnVoirdcc") }}</span
                >
                <span
                  style="
                    cursor: pointer;
                    font-weight: 900;
                    color: rgb(51, 127, 168);
                    font-size: 13px !important;
                  "
                  @click="openChargingDetail(item)"
                >
                  {{ $t("modalPanier.btnVoirdc") }}
                </span>
                <img
                  @click="deleteOneRecharge(item)"
                  src="@/assets/header/logos/bin.png"
                  width="15"
                  style="cursor: pointer"
                />
              </div>
            </template>
            <template v-slot:item.paymentStatus="{ item }">
              <span
                class="btn"
                style="border-radius: 5px !important;
                padding: 0 10px !important;
                width: 100px;"
                v-bind:style="getColorEtatService(item?.paymentStatus)"
              >
                {{ formatStatus(item?.paymentStatus) }} 
              </span>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-text v-else-if="isServices">
          <v-data-table
            :headers="headersServices"
            :items="serviceDesserts ? serviceDesserts : []"
            :items-per-page="serviceDesserts ? 5 : 0"
            :no-data-text="$t('no-data')"
            :footer-props="{
              itemsPerPageText: $t('nbpages'),
              itemsPerPageAllText: $t('all_pages'),
              itemsPerPageOptions: [5, 10, 15, 20, 25, 50, 100, -1],
            }"
            :disable-sort="$vuetify.breakpoint.smAndDown"
            class="elevation-1 custom-invoices-list"
          >
          <div class="d-none">{{item}}</div>
      
            <template v-slot:item.billerRs="{ item }">
              {{ getMinistre(item?.billerRs)}}
            </template>
            <template v-slot:item.objectOriginalAmount="{ item }">
              {{ getMontant(item?.objectOriginalAmount)}}
            </template>
            <template v-slot:item.Actions="{ item }">
              <div
                class="d-flex align-items-center justify-content-center"
                style="gap: 15px"
              >
                <button
                  v-if="item?.paymentStatus == 'Available'"
                  @click="openDetailService(item)"
                  class="btn-icon"
                  style="
                    font-weight: 900;
                    color: rgb(51, 127, 168);
                    font-size: 13px !important;
                  "
                >
                {{$t("modalPanier.pay")}}

                </button>
                <button
                  v-else-if="
                    item?.paymentStatus == 'PAID' ||
                    item?.paymentStatus == 'HPAID'
                  "
                  @click="openDetailService(item)"
                  class="btn-icon"
                  :disabled="item?.paymentStatus == 'HPAID'"
                  :title="
                    item?.paymentStatus == 'HPAID' ?  $t('modalPanier.Ailleurs')  : ''
                  "
                  style="
                    font-weight: 900;
                    color: #9196a8;
                    font-size: 13px !important;
                  "
                >
                {{$t("modalPanier.btnVoirdc")}}
                </button>
                <img
                  @click="deleteOneService(item)"
                  src="@/assets/header/logos/bin.png"
                  width="15"
                  style="cursor: pointer"
                />
              </div>
            </template>
            <template v-slot:item.paymentStatus="{ item }">
              <span
                class="btn"
                style="border-radius: 5px !important;
                padding: 0 10px !important;
                width: 100px;"
                v-bind:style="getColorEtat(item?.paymentStatus)"
              >
                {{ getNameEtat(item?.paymentStatus) }}
              </span>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions v-if="isBill">
          <div class="container">
            <div
              style="
                font-size: 30px;
                color: #337fa8;
                font-weight: 300;
                font-family: 'Source Sans Pro';
                text-align: center;
              "
              class="small-header pay-header mb-3"
            >
              <span> {{ $t("modalPanier.total") }} </span>
              :
              <span style="font-weight: 600"
                >{{ getTotalPrix() }} {{ $t("modalPanier.TND") }}</span
              >
            </div>
            <div
              class="d-flex align-items-center justify-content-center"
              style="gap: 15px; marin-top: 20px"
            >
              <button @click="deleteAll()" class="btn-turquoise">
                {{ $t("modalPanier.retirer") }}
              </button>
              <button
                @click="
                  newSearch();
                  showNowBurger();
                "
                class="btn-turquoise-outline"
              >
                <img src="@/assets/header/logos/plus.png" class="scale" />
                {{ $t("modalConsultation.AjoutFacture") }}
              </button>
            </div>
          </div>
        </v-card-actions>
        <v-card-actions v-else-if="isCharging">
          <div class="container">
            <div
              class="d-flex align-items-center justify-content-center"
              style="gap: 15px; marin-top: 20px"
            >
              <button @click="deleteAllRecharges()" class="btn-turquoise">
                {{ $t("modalPanier.retireracc") }}
              </button>
              <button
                @click="
                  newSearch();
                  showNowBurger();
                "
                class="btn-turquoise-outline"
              >
                <img src="@/assets/header/logos/plus.png" class="scale" />
                {{ $t("modalConsultation.addAccount") }}
              </button>
            </div>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="payDialog"
      width="30%"
      overlay-color="white"
      overlay-opacity="0.5"
    >
      <v-card>
        <v-toolbar>
          <v-toolbar-title
            style="margin: 5px 10px; color: rgb(25, 153, 194); font-size: 28px"
            >{{ $t("paiement") }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon @click="closePaiment()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <div class="modal-body">
            <div class="container">
              <div class="row">
                <div
                  class="col-sm choose-meth"
                  style="
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                  "
                  v-if="isCard"
                >
                  <div
                    class="choose-meth-icon"
                    @click="openWindow('C', '998-02-C')"
                  >
                    <img
                      src="@/assets/images/carte.png"
                      alt="set payment"
                      class="scale"
                    />
                  </div>

                  <span class="spanStyle pay-header">
                    {{ $t("modalpaiement.Carte") }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="detailDialog"
      width="87%"
      height="94%"
      overlay-color="white"
      overlay-opacity="0.5"
      class="custom-dialog-bill"

    >
      <v-card>
        <div  class="container p-3" style='color: #2c3e50 ; font-family:"Roboto" !important'>
        <v-icon
        @click="closeDescr"

        class="icon-bill p-0 position-absolute top-0 "
        :class="[
          $i18n.locale === 'fr' || $i18n.locale === 'en' ? 'end-0' : 'start-0',
        ]"
         style="color: #1999c2;width: 20px !important;
      height: 20px !important ;margin: 10px;

      border-radius: 50% !important;">
          mdi-close-circle-outline
        </v-icon>
        <div class="text-center  border-bottom"
        style="
    display: flex;
    align-items: center;
    justify-content: center;
"
        >
          <img src="@/assets/header/logos/monettique.png"
            style=" width: 110px !important;
              box-shadow: 0px 2px 48px rgba(0, 0, 0, 0.04);
          margin: 10px 10px  "
          />
        <h3  v-if="invoiceData?.paymentStatus &&
            invoiceData?.paymentStatus?.toLowerCase() !== 'paid'"           style="color: rgb(25, 153, 194);padding: 0px !important;font-size: calc(1.3rem + 0.6vw) !important;margin-bottom: 0.5rem;font-weight: 400 !important;line-height: 1.1 !important;font-family: inherit;" class="text-center">
            {{ $t("modalDetail.facdetails") }}
          </h3>
          <h3  v-if="invoiceData?.paymentStatus &&
            invoiceData?.paymentStatus?.toLowerCase() === 'paid'"           style="color: rgb(25, 153, 194);padding: 0px !important;font-size: calc(1.3rem + 0.6vw) !important;margin-bottom: 0.5rem;font-weight: 400 !important;line-height: 1.1 !important;font-family: inherit;" class="text-center">
            {{ $t("modalDetail.facdetailsPaid") }}
          </h3>
        </div>
        <div class="row p-3">
          <div
          v-if="invoiceData?.paymentStatus &&
            invoiceData?.paymentStatus?.toLowerCase() === 'paid'"            
            class="col-md-5 d-flex flex-column border border-1"
            style="display: flex !important;justify-content: space-evenly;align-items: center;"          >
            <img
              class="receipt-img"
              src="@/assets/receipt.png"
              style="
                margin-left: auto;
                width: 75%;
                margin-right: auto;
                height: 60%;
                box-shadow: 0px 2px 48px rgba(0, 0, 0, 0.04);
              "
            />
            <!-- <div
              class="btn-send"
              style="display: flex; justify-content: space-evenly"
            > -->
              <button
              v-if="
                    invoiceData?.paymentStatus &&
                    invoiceData?.paymentStatus?.toLowerCase() === 'paid'
                  "        
                  :disabled="invoiceData?.paymentStatus == 'PAID' && invoiceData?.paymentsList.length === 0"
                  :title="(invoiceData?.paymentStatus == 'PAID' && invoiceData?.paymentsList.length === 0) ? $t('modalPanier.Ailleurs')  : '' "
                  @click="printRecu(invoiceData)"

                  style="
                  color: white !important;
                  margin-bottom: 10px;
                  border: 2px solid #337fa8;
                  background-color: #337fa8;
                  border-radius: 10px;
                  padding: 10px;
                "
              >
                {{ $t("imprimer") }}
        </button>
            <!-- </div> -->
          </div>
          <div
          v-if="invoiceData?.paymentStatus != 'PAID'"
            class="col-md-5 d-flex flex-column align-items-center border border-1"
            style="gap: 15px"
          >
            <span  v-if="invoiceData?.paymentStatus != 'PAID'"
             class="text-center fw-bold text-dark">
              {{ $t("modalQr") }}
            </span>
            <qr-code
              style="pointer-events: none"
              :size="290"
              class="qr-code mt-0"
              v-if="invoiceData?.paymentStatus != 'PAID'"
              :text="qrcode"
            >
            </qr-code>
            <span v-if="invoiceData?.paymentStatus != 'PAID'" class="text-center text-muted">{{
              $t("dispoQr")
            }}</span>
              <div
                  v-if="invoiceData?.paymentStatus != 'PAID'"
                  class="d-flex align-items-center justify-content-center flex-wrap mt-2"
                >
               <div class="box-wallet">
                    <img
                      class="img-code"
                      src="@/assets/images/details-invoice-logos/LogoEndaTao.png"
                      style="width: 100%"
                    />
                  </div>
                  <div class="box-wallet">
                    <img
                      class="img-code"
                      src="@/assets/images/details-invoice-logos/izi.png"
                      style="width:92%"                     />
                  </div>
                  <div class="box-wallet">
                    <img
                      class="img-code"
                      src="@/assets/images/details-invoice-logos/amen-w.jpg"
                      style="width: 75%"                     />
                  </div>
                  <div class="box-wallet">
                    <img
                      class="img-code"
                      src="@/assets/images/details-invoice-logos/atb-w.png"
                      style="width: 85%"                    />
                  </div>
                  <div class="box-wallet">
                    <img
                      class="img-code"
                      src="@/assets/all_new_images/ettijeri.png"
                      style="width: 93%"                    />
                  </div>
                  <div class="box-wallet">
                    <img
                      class="img-code"
                      src="@/assets/images/bnaWallet.png" style="width: 55%" />
                  </div>
                  <div class="box-wallet">
                    <img
                      class="img-code"
                      src="@/assets/images/details-invoice-logos/new-bt-pay.png"
                      style="width: 75%"                    />
                  </div>
                  <div class="box-wallet">
                    <img
                      class="img-code"
                      src="@/assets/all_new_images/bte.png"
                      style="width: 95%"                    />
                  </div>
                  <div class="box-wallet">
                    <img
                      class="img-code"
                      src="@/assets/images/details-invoice-logos/stb-pay.png"
                      style="width: 85%"                    />
                  </div>
                  <div class="box-wallet">
                    <img
                      src="@/assets/images/details-invoice-logos/Go.png"
                      style="width: 55%"
                    />
                  </div>
                  <div class="box-wallet">
              <img src="@/assets/images/btkPay.png" style="width: 60%" />
            </div>
     
            <div class="box-wallet">
              <img src="@/assets/images/wafaCash.png" style="width: 60%" />
            </div>
            <div class="box-wallet">
              <img src="@/assets/images/details-invoice-logos/BhWallet.png" style="width: 65%" />
            </div>
                </div>
                <div
                  style="display: flex"
                  v-if="invoiceData?.paymentStatus != 'PAID'"
                  class="choose-meth-icon"
                >
                  <p style="font-size: 12px">{{ $t("refreshMsg") }}</p>
                  <img
                    @click="refreshInv(invoiceData)"
                    style="cursor: pointer; width: 30px; height: 28px"
                    src="@/assets/refresh.png"
                  />
                </div>
          </div>
          <div class="col-md-7" style="gap: 15px">
            <div style="font-weight: 400; display: flex">
             {{ $t("modalDetail.Bienvenue")}}
              <!-- <span style="font-weight: 700; margin: 0 5px">{{
                invoiceData.prenomClient ||
                invoiceData.prenomClient.toUpperCase() +
                  " " +
                  invoiceData.nomClient.toUpperCase()
              }}</span> -->
              <span style="font-weight: 700; margin: 0 5px" v-if="invoiceData?.billerRs == 'SONEDE'">
                    {{ invoiceData?.clientLastname }}
                  </span>
                  <span style="font-weight: 700; margin: 0 5px" v-if="invoiceData?.billerRs == 'STEG'">
                    {{ invoiceData?.clientName}}
                  </span>
                  <span style="font-weight: 700; margin: 0 5px" v-if="invoiceData?.billerRs == 'TOPNET'">
                    {{ invoiceData?.clientLastname }} {{ invoiceData?.clientName}}
                  </span>
            </div>
            <div class="logo-data" style="display: flex; align-items: center">
              <img width="70" class="logo-fact-img" :src="getImage(invoiceData?.biller)"
 />
              <div    :class="[
          $i18n.locale === 'ar' || $i18n.locale === 'tn' ? 'logo-data-ar' : '',
        ]" class="logo-data-fact" style="border-left: 3px dashed #e0e0e0">
                <div 
                  class="responsive-table-title-data-all"
                  style="display: flex"
                >
                  <span class="responsive-table-title"
                    >{{ $t("modalDetail.Reference") }} :
                  </span>
                  <span
                    class="responsive-table-title-data"
                    style="margin-left: 5px"
                    >{{ invoiceData?.invoiceRef }}
                  </span>
                </div>
                <div class="btn-data-responsive">
                  <button
                    type="button"
                    :class="getColorEtatBill(invoiceData?.paymentStatus)"

                    class="button-ar"
                    style="
                      text-transform: capitalize;
                      cursor: auto;
                      /* margin-left: 40%; */
                    "
                  >
                  {{ getNameEtat(invoiceData?.paymentStatus) }}
                  </button>
                </div>
              </div>
            </div>
  
            <div
              v-for="(key, i) in invoiceKeys"
              :key="i"
              class="mt-2 detail-container"
              style="font-weight: 500"
            >
              <span class="labelText">   {{ $t(`modalDetail.${key}`) }} :</span>
              <span style="text-transform: capitalize">
                {{ formatDetail[key] }}
              </span>
            </div>
          <div>
            <div
              style="
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 5px;
                justify-content: center;
                /* margin: 0; */
                padding: 0;
                margin: 25px 0px;
              "
            >
            <button
              :disabled="invoiceData?.paymentStatus == 'PAID'"
               v-if="invoiceData?.paymentStatus != 'PAID'"
               @click="openPaimentDesc(invoiceData)"
                class="btn-res"
                style="font-size: 14px !important"
              >
              
                <!-- <font-awesome-icon icon="fa-solid fa-shopping-cart" /> -->
                <font-awesome-icon icon="credit-card" />
                
                {{ $t("modalDetail.PayerM") }}
              </button>
              <button
              @click="goToPro()"
              class="btn-paid"
                style="font-size: 12px !important"
              >
                {{ $t("modalDetail.Creer") }}
              </button>
            </div>
              <button   :disabled="invoiceData?.paymentStatus == 'PAID'"
v-if="invoiceData?.paymentStatus != 'PAID'"
   class="detail-modal-all-virement"
    style = "display:flex ;padding: 5px; align-items: center; margin: 0 auto;" >
<span class="text-virement" style="padding-right:5px;font-size: 14px "> {{ $t("modalDetail.PayerV") }}
</span> 

    <div class="box-wallet-1" >
      <a href="https://www.attijarirealtime.com.tn/index.ebk"  target="_blank">

      <img style="padding-right: 5px;"
        class="img-code"
        src="@/assets/all_new_images/tijari.png"
        width="48"
        height="auto"
      />
    </a>

    </div>
    <div class="box-wallet-1" >
      <a href="https://ebanking.bna.tn/Ebanking/modules/members/login.cm"  target="_blank">

      <img style="padding-right: 5px;"
        class="img-code"
        src="@/assets/images/bnaV.jpeg"
        width="55"
        height="auto"
      />
      </a>
    </div>

    <div class="box-wallet-1" >
      <a href="https://www.bte.com.tn/fr/bte-services/bte-net"  target="_blank">

      <img style="padding-right: 5px;"
        class="img-code"
        src="@/assets/all_new_images/bte.png"
        width="60"
        height="auto"
      />
      </a>
  </div>
 </button>
        
            </div>
            <p
              v-if="!invoiceData?.isPaid"
              style="
                font-size: 9px;
                font-weight: 600;
                margin-top: 15px;
                width: 100%;
              "
            >
              {{ $t("euroTest") }}
            </p>
          </div>
        </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="detailDialogService"
      width="80%"
      height="80%"
      overlay-color="white"
      overlay-opacity="0.5"
    >
    <v-card>
        <div
          class="container p-3"
          style="color: #2c3e50; font-family: 'Roboto' !important"
        >
          <v-icon
            @click="closeDescrService"
            class="icon-bill p-0 position-absolute top-0"
            :class="[
              $i18n.locale === 'fr' || $i18n.locale === 'en'
                ? 'end-0'
                : 'start-0',
            ]"
            style="
              color: #1999c2;
              width: 20px !important;
              height: 20px !important ;
              margin: 10px;

              border-radius: 50% !important;
            "
          >
            mdi-close-circle-outline
          </v-icon>
          <div class="row border-bottom">
            <h3
              style="
                color: rgb(25, 153, 194);
                padding: 0px !important;
                font-size: calc(1.3rem + 0.6vw) !important;
                margin-bottom: 0.5rem;
                font-weight: 400 !important;
                line-height: 1.1 !important;
                font-family: inherit;
              "
              class="text-center"
            >
              {{ $t("modalDetail.Inscription") }}
            </h3>
          </div>
          <div class="row p-3">
            <div
              v-if="service?.paymentStatus == 'PAID'"
              class="col-md-5 d-flex flex-column border border-1"
              style="
                display: flex !important;
                justify-content: space-evenly;
                align-items: center;
              "
            >
              <img
                class="receipt-img"
                src="@/assets/receipt.png"
                style="
                  margin-left: auto;
                  width: 75%;
                  margin-right: auto;
                  height: 60%;
                  box-shadow: 0px 2px 48px rgba(0, 0, 0, 0.04);
                "
              />
              <!-- <div
              class="btn-send"
              style="display: flex; flex-direction: column; justify-content: space-evenly"
            > -->
              <button
                v-if="
                  service?.paymentStatus &&
                  service?.paymentStatus?.toLowerCase() === 'paid'
                "
                @click="printServiceRecu(service)"
                style="
                  color: white !important;
                  margin-bottom: 10px;
                  border: 2px solid #337fa8;
                  background-color: #337fa8;
                  border-radius: 10px;
                  padding: 10px;
                "
              >
                {{ $t("imprimer") }}
              </button>
              <button
                v-if="
                  service?.paymentStatus &&
                  service?.paymentStatus?.toLowerCase() === 'paid'
                "
                @click="recuCnte(service)"
                style="
                  color: #337fa8 !important;
                  margin-bottom: 10px;
                  border: 2px solid #337fa8;
                  background-color: white;
                  border-radius: 10px;
                  padding: 10px;
                "
              >
                {{ $t("recuCnte") }}
              </button>
              <!-- </div> -->
            </div>
            <div
              v-if="service?.paymentStatus != 'PAID'"
              class="service-class col-md-5 d-flex flex-column align-items-center border border-1"
              style="gap: 27px"
            >
              <span
                v-if="service?.paymentStatus != 'PAID'"
                class="text-center fw-bold text-dark"
              >
                {{ $t("modalQr") }}
              </span>
              <!-- <div
                v-if="service?.paymentStatus != 'PAID'"
                style="
                  width: auto;
                  height: 150px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <p>QR bientôt disponible</p>
              </div> -->
              <qr-code
              style="pointer-events: none"
              :size="290"
              class="qr-code mt-0"
              v-if="service?.paymentStatus != 'PAID'"
              :text="qrcodeService"
            >
            </qr-code>
            <span v-if="service?.paymentStatus != 'PAID'" class="text-center text-muted">{{
              $t("dispoQr")
            }}</span>
              <div
                  v-if="service?.paymentStatus != 'PAID'"
                  class="d-flex align-items-center justify-content-center flex-wrap mt-2"
                >
               <div class="box-wallet">
                    <img
                      class="img-code"
                      src="@/assets/images/details-invoice-logos/LogoEndaTao.png"
                      style="width: 100%"
                    />
                  </div>
                  <div class="box-wallet">
                    <img
                      class="img-code"
                      src="@/assets/images/details-invoice-logos/izi.png"
                      style="width:92%"                     />
                  </div>
                  <div class="box-wallet">
                    <img
                      class="img-code"
                      src="@/assets/images/details-invoice-logos/amen-w.jpg"
                      style="width: 75%"                     />
                  </div>
                  <div class="box-wallet">
                    <img
                      class="img-code"
                      src="@/assets/images/details-invoice-logos/atb-w.png"
                      style="width: 85%"                    />
                  </div>
                  <div class="box-wallet">
                    <img
                      class="img-code"
                      src="@/assets/all_new_images/ettijeri.png"
                      style="width: 93%"                    />
                  </div>
                  <div class="box-wallet">
                    <img
                      class="img-code"
                      src="@/assets/images/bnaWallet.png" style="width: 55%" />
                  </div>
                  <div class="box-wallet">
                    <img
                      class="img-code"
                      src="@/assets/images/details-invoice-logos/new-bt-pay.png"
                      style="width: 75%"                    />
                  </div>
                  <div class="box-wallet">
                    <img
                      class="img-code"
                      src="@/assets/all_new_images/bte.png"
                      style="width: 95%"                    />
                  </div>
                  <div class="box-wallet">
                    <img
                      class="img-code"
                      src="@/assets/images/details-invoice-logos/stb-pay.png"
                      style="width: 85%"                    />
                  </div>
                  <div class="box-wallet">
                    <img
                      src="@/assets/images/details-invoice-logos/Go.png"
                      style="width: 55%"
                    />
                  </div>
                  <div class="box-wallet">
              <img src="@/assets/images/btkPay.png" style="width: 60%" />
            </div>
     
            <div class="box-wallet">
              <img src="@/assets/images/wafaCash.png" style="width: 60%" />
            </div>
            <div class="box-wallet">
              <img src="@/assets/images/details-invoice-logos/BhWallet.png" style="width: 65%" />
            </div>
                </div>
              <div
                style="display: flex"
                v-if="service?.paymentStatus != 'PAID'"
                class="choose-meth-icon"
              >
                <p style="font-size: 12px">{{ $t("refreshMsg") }}</p>
                <img
                  @click="refreshService(service)"
                  style="cursor: pointer; width: 30px; height: 28px"
                  src="@/assets/refresh.png"
                />
              </div>
            </div>
            <div class="col-md-7" style="gap: 15px">
              <div style="font-weight: 400; display: flex">
                {{ $t("modalDetail.Bienvenue") }}

                <span style="font-weight: 700; margin: 0 5px">
                  {{ service?.clientName }}
                </span>
              </div>
              <div class="logo-data" style="display: flex; align-items: center">
                <img
                  width="70"
                  class="logo-fact-img"
                  :src="getImage(service?.biller)"
                />
                <div
                  :class="[
                    $i18n.locale === 'ar' || $i18n.locale === 'tn'
                      ? 'logo-data-ar'
                      : '',
                  ]"
                  class="logo-data-fact data-design"
                  style="border-left: 3px dashed #e0e0e0"
                >
                  <div
                    class="responsive-table-title-data-all"
                    style="display: flex"
                  >
                    <span class="responsive-table-title">
                      {{ $t("modalConsultation.idUnique") }}
                      :
                    </span>
                    <span
                      class="responsive-table-title-data"
                      style="margin-left: 5px"
                      >{{ service?.objectRef }}
                    </span>
                  </div>
                  <div class="btn-data-responsive">
                    <button
                      type="button"
                      :class="getColorEtatBill(service?.paymentStatus)"
                      class="button-ar"
                      style="
                        text-transform: capitalize;
                        cursor: auto;
                        /* margin-left: 40%; */
                      "
                    >
                      {{ getNameEtat(service?.paymentStatus) }}
                    </button>
                  </div>
                </div>
              </div>

              <div
              v-for="(key, i) in serviceKeys"
              :key="i"
              class="mt-2 detail-container"
              style="font-weight: 500"
            >
              <span class="labelText">   {{ $t(`modalDetail.${key}`) }} :</span>
              <span style="text-transform: capitalize">
                {{ formatDetailService[key] }}
              </span>
            </div>
<div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  flex-wrap: wrap;
                  gap: 5px;
                  justify-content: center;
                  /* margin: 0; */
                  padding: 0;
                  margin: 25px 0px;
                "
              >
              <button
                  :disabled="
                    service?.paymentStatus == 'PAID' ||
                    service?.paymentStatus == 'HPAID'
                  "
                  v-if="service?.paymentStatus != 'PAID'"
                  @click="openPaimentService()"
                  class="btn-res"
                  style="font-size: 14px !important"
                >
                  <!-- <font-awesome-icon icon="fa-solid fa-shopping-cart" /> -->
                  <font-awesome-icon icon="credit-card" />

                  {{ $t("modalDetail.PayerM") }}
                </button>
                <button
                  @click="goToPro()"
                  class="btn-paid"
                  style="font-size: 12px !important"
                >
                  {{ $t("modalDetail.Creer") }}
                </button>
              </div>
                <button  
                :disabled="
                    service?.paymentStatus == 'PAID' ||
                    service?.paymentStatus == 'HPAID'
                  "
                  v-if="service?.paymentStatus != 'PAID'"

   class="detail-modal-all-virement"
    style = "display:flex ;padding: 5px; align-items: center; margin: 0 auto;" >
<span  class="text-virement"  style="padding-right:5px;font-size: 14px "> {{ $t("modalDetail.PayerV") }}
</span> 
    <div class="box-wallet-1" >
      <a href="https://www.attijarirealtime.com.tn/index.ebk"  target="_blank">

      <img style="padding-right: 5px;"
        class="img-code"
        src="@/assets/all_new_images/tijari.png"
        width="48"
        height="auto"
      />
    </a>

    </div>
    <div class="box-wallet-1"   target="_blank">
      <a href="https://ebanking.bna.tn/Ebanking/modules/members/login.cm">

      <img style="padding-right: 5px;"
        class="img-code"
        src="@/assets/images/bnaV.jpeg"
        width="55"
        height="auto"
      />
      </a>
    </div>

    <div class="box-wallet-1"  target="_blank">
      <a href="https://www.bte.com.tn/fr/bte-services/bte-net">

      <img style="padding-right: 5px;"
        class="img-code"
        src="@/assets/all_new_images/bte.png"
        width="60"
        height="auto"
      />
      </a>
    </div>
 </button>
              </div>
              <p
                v-if="
                  service?.paymentStatus &&
                  service?.paymentStatus?.toLowerCase() !== 'paid'
                "
                style="
                  font-size: 9px;
                  font-weight: 600;
                  margin-top: 15px;
                  width: 100%;
                "
              >
                {{ $t("euroTest") }}
              </p>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="rechargeDialog"
      width="auto"
      height="auto"
      overlay-color="white"
      overlay-opacity="0.5"
 >
      <v-card  min-width = "300px"  
      max-width = "450px" >
        <!-- <h5 class="text-center p-4">{{ $t("chooseamount") }}</h5> -->
        <!-- <div class="d-flex align-items-center flex-column" style="gap: 20px">
          <input
            type="number"
            :value="price ? price : setPrice().price"
            :min="setPrice().min"
            :step="setPrice().step"
            :max="setPrice().max"
            @input="changePrice($event)"
            class="form-control w-75 mx-auto"
            id="price"
            :placeholder="$t('chooseamount')"
            onkeypress="return false"
            onkeydown="return false"
          />
          <div
            class="d-flex align-items-center justify-content-center pb-3 m-3"
            style="gap: 15px"
          >
            <button
              class="btn-turquoise-outline"
              style="width: 100px !important"
              @click="
                () => {
                  this.rechargeDialog = false;
                  this.price = null;
                }
              "
            >
              {{ $t("annuler") }}
            </button>
            <button
              class="btn-turquoise"
              style="width: 100px !important"
              color="error"
              @click="
                () => {
                  this.rechargeDialog = false;
                  this.openChargingCharge();
                }
              "
            >
              <v-icon style="color: white" class="btn-icon btn-delete"
                >mdi-check
              </v-icon>
              {{ $t("valider") }}
            </button>
          </div>
        </div> -->
        <div class="modals">
            <div style="margin-top: 20px" class="modals-header">
              {{ $t("chooseamount") }}
            </div>
            <div class="modals-body mt-4">
              <input
            type="number"
            :value="price ? price : setPrice().price"
            :min="setPrice().min"
            :step="setPrice().step"
            :max="setPrice().max"
            @input="changePrice($event)"
            class="form-control w-75 mx-auto input-number"
            id="price"
            :placeholder="$t('chooseamount')"
            onkeypress="return false"
            onkeydown="return false"

          />
              <div class="revoke-token-btns mt-5">
                <button class="text-capitalize btn-closed" @click="
                () => {
                  this.rechargeDialog = false;
                  this.price = null;
                }
              ">
                  {{ $t("annuler") }}
                </button>
                <button
                  class="text-capitalize btn-add"
                  color="error"
                  @click="
                () => {
                  this.rechargeDialog = false;
                  this.openChargingCharge();
                }
              "                >
                  <v-icon style="color: white" class="btn-icon btn-delete"
                    >mdi-check
                  </v-icon>
                  {{ $t("valider") }}
                </button>
              </div>
            </div>
          </div>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="detailChargingDialog"
      width="90%"
      height="87%"
      overlay-color="white"
      overlay-opacity="0.5"
    >
    <v-card>
        <div  class="container p-3" style='color: #2c3e50 ; font-family:"Roboto" !important'>
        <v-icon
        @click="closeChargeDetail()"

        class="icon-bill p-0 position-absolute top-0 "
        :class="[
          $i18n.locale === 'fr' || $i18n.locale === 'en' ? 'end-0' : 'start-0',
        ]"
         style="color: #1999c2;width: 20px !important;
      height: 20px !important ;margin: 10px;

      border-radius: 50% !important;">
          mdi-close-circle-outline
        </v-icon>
        <div class="row border-bottom">
          <h3 style="color: rgb(25, 153, 194);padding: 0px !important;font-size: calc(1.3rem + 0.6vw) !important;margin-bottom: 0.5rem;font-weight: 400 !important;line-height: 1.1 !important;font-family: inherit;" class="text-center">
            {{ $t("rechargeabn") }}
          </h3>
        </div>
        <div class="row p-3">
          <div
            class="col-md-6 d-flex flex-column align-items-center border border-1"
            style="gap: 15px"
          >
            <span 
             class="text-center fw-bold text-dark">
              {{ $t("modalQr") }}
            </span>
            <qr-code
              style="pointer-events: none"
              :size="290"
              class="qr-code mt-0"
              v-if="recharge?.billerRib"
            :text="recharge?.billerRib"
            >
            </qr-code>
            <span  class="text-center text-muted">{{
              $t("dispoQr")
            }}</span>
              <div
            class="d-flex align-items-center justify-content-center flex-wrap mt-2"
          >
              <div class="box-wallet">
              <img
                class="img-code"
                src="@/assets/images/details-invoice-logos/LogoEndaTao.png"
                style="width: 100%"
              />
            </div>
            <div class="box-wallet">
              <img
                class="img-code"
                src="@/assets/images/details-invoice-logos/izi.png"
                width="40"
              />
            </div>
            <div class="box-wallet">
              <img
                class="img-code"
                src="@/assets/images/details-invoice-logos/amen-w.jpg"
                width="50"
              />
            </div>
            <div class="box-wallet">
              <img
                class="img-code"
                src="@/assets/images/details-invoice-logos/atb-w.png"
                width="60"
              />
            </div>
         <div class="box-wallet">
              <img
                class="img-code"
                src="@/assets/all_new_images/ettijeri.png"
                width="62"
              />
            </div>
            <div class="box-wallet">
              <img
                class="img-code"
                src="@/assets/images/bnaWallet.png" style="width: 55%" />

            </div>
          <div class="box-wallet">
              <img
                class="img-code"
                src="@/assets/images/details-invoice-logos/new-bt-pay.png"
                width="50"
              />
            </div>
            <div class="box-wallet">
              <img
                class="img-code"
                src="@/assets/all_new_images/bte.png"
                width="70"
              />
            </div>
           
            <div class="box-wallet">
              <img
                class="img-code"
                src="@/assets/images/details-invoice-logos/stb-pay.png"
                width="60"
              />
            </div>
            <div class="box-wallet">
                    <img
                      src="@/assets/images/details-invoice-logos/Go.png"
                      style="width: 55%"
                    />
                  </div>

                  <div class="box-wallet">
              <img src="@/assets/images/btkPay.png" style="width: 60%" />
            </div>
     
            <div class="box-wallet">
              <img src="@/assets/images/wafaCash.png" style="width: 60%" />
            </div>
            <p
              style="
                font-size: 10px;
                font-weight: 600;
                margin-top: 15px;
                width: 100%;
              "
            >
              {{ $t("staLogo") }}
            </p>
       
          </div>
                <div
                  style="display: flex"
              
                  class="choose-meth-icon"
                >
                  <p style="font-size: 12px">{{ $t("refreshMsgAbn") }}</p>
                  <img
                  @click="verifyRechargeWithoutMsg(recharge?.paymentId)"
                    style="cursor: pointer; width: 30px; height: 28px"
                    src="@/assets/refresh.png"
                  />
                </div>
          </div>
          <div class="col-md-6" style="gap: 15px">
            <div style="font-weight: 400; display: flex">
             {{ $t("modalDetail.Bienvenue")}}

            </div>
            <div class="logo-data" style="display: flex; align-items: center">
              <img width="120" class="logo-fact-img" :src="getImage('STA')"

 />
              <div    :class="[
          $i18n.locale === 'ar' || $i18n.locale === 'tn' ? 'logo-data-ar' : '',
        ]"  class="logo-data-fact data-design"
                style="border-left: 3px dashed #e0e0e0">
                <div 
                  class="responsive-table-title-data-all"
                  style="display: flex"
                >
                  <span class="responsive-table-title"
                    >{{ $t("modalDetail.Reference") }} :
                  </span>
                  <span
                    class="responsive-table-title-data"
                    style="margin-left: 5px">
                    {{ recharge?.rechargeRef }}

                  </span>
                </div>
  
              </div>
            </div>
  
            <div
              v-for="(key, i) in rechargeKeys"
              :key="i"
              class="mt-2 detail-container"
              style="font-weight: 500"
            >
              <span class="labelText">   {{ $t(`modalDetail.${key}`) }} :</span>
              <span style="text-transform: capitalize">
                {{ formatDetailRecharge[key] }}
              </span>
            </div>
          <div>
            <div
              style="
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 5px;
                justify-content: center;
                padding: 0;
                margin: 25px 0px;
              "
            >
            <button
              @click="rechargeWithCard"

                class="btn-res"
                style="font-size: 14px !important"
              >
                <!-- <font-awesome-icon icon="fa-solid fa-shopping-cart" /> -->
                <font-awesome-icon icon="credit-card" />

                {{ $t("modalDetail.PayerM") }}
              </button>
              <button
              @click="goToPro()"
              class="btn-paid"
                style="font-size: 12px !important"
              >
                {{ $t("modalDetail.Creer") }}
              </button>
         
  
        
            </div>
            <button  
   class="detail-modal-all-virement"
    style = "display:flex ;padding: 5px; align-items: center; margin: 0 auto;" >
<span  class="text-virement"  style="padding-right:5px;font-size: 14px "> {{ $t("modalDetail.PayerV") }}
</span> 
    <div class="box-wallet-1" >
      <a href="https://www.attijarirealtime.com.tn/index.ebk"  target="_blank">

      <img style="padding-right: 5px;"
        class="img-code"
        src="@/assets/all_new_images/tijari.png"
        width="48"
        height="auto"
      />
    </a>

    </div>
    <div class="box-wallet-1" >
      <a href="https://ebanking.bna.tn/Ebanking/modules/members/login.cm"  target="_blank">

      <img style="padding-right: 5px;"
        class="img-code"
        src="@/assets/images/bnaV.jpeg"
        width="55"
        height="auto"
      />
      </a>
    </div>

    <div class="box-wallet-1" >
      <a href="https://www.bte.com.tn/fr/bte-services/bte-net"  target="_blank">

      <img style="padding-right: 5px;"
        class="img-code"
        src="@/assets/all_new_images/bte.png"
        width="60"
        height="auto"
      />
      </a>
    </div>
 </button>
        
            </div>
            <p

              style="
                font-size: 9px;
                font-weight: 600;
                margin-top: 15px;
                width: 100%;
              "
            >
              {{ $t("euroTest") }}
            </p>
          </div>
        </div>
      </div>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="moreDetailChargingDialog"
      width="80%"
      height="80%"
      overlay-color="white"
      overlay-opacity="0.5"
    >
    <v-card>
        <div  class="container p-3" style='color: #2c3e50 ; font-family:"Roboto" !important'>
        <v-icon
        @click="moreDetailChargingDialog = false"

        class="icon-bill p-0 position-absolute top-0 "
        :class="[
          $i18n.locale === 'fr' || $i18n.locale === 'en' ? 'end-0' : 'start-0',
        ]"
         style="color: #1999c2;width: 20px !important;
      height: 20px !important ;margin: 10px;

      border-radius: 50% !important;">
          mdi-close-circle-outline
        </v-icon>
        <div   class="row border-bottom">
          <h3 style="color: rgb(25, 153, 194);padding: 0px !important;font-size: calc(1.3rem + 0.6vw) !important;margin-bottom: 0.5rem;font-weight: 400 !important;line-height: 1.1 !important;font-family: inherit;" class="text-center">
            {{ $t("detailabn") }}
          </h3>
        </div>
        <div  class="row p-3">
          <div 
          v-if=" recharge?.lastRechargeAmount && recharge?.lastRechargeDate"
            class="col-md-5 d-flex flex-column border border-1"
            style="display: flex !important;justify-content: space-evenly;align-items: center;"          >
            <img
            v-if=" recharge?.lastRechargeAmount && recharge?.lastRechargeDate"
              class="receipt-img"
              src="@/assets/receipt.png"
              style="
                margin-left: auto;
                width: 75%;
                margin-right: auto;
                height: 60%;
                box-shadow: 0px 2px 48px rgba(0, 0, 0, 0.04);
              "
            />
            <!-- <div
              class="btn-send"
              style="display: flex; justify-content: space-evenly"
            > -->
              <button
              v-if="recharge?.lastRechargeAmount && recharge?.lastRechargeDate
                  "
                   @click="printRechargeRecu(recharge)"
 

                  style="
                  color: white !important;
                  margin-bottom: 10px;
                  border: 2px solid #337fa8;
                  background-color: #337fa8;
                  border-radius: 10px;
                  padding: 10px;
                "
              >
                {{ $t("imprimer") }}
        </button>
            <!-- </div> -->
          </div>
          <div :class="recharge?.lastRechargeAmount && recharge?.lastRechargeDate ? 'col-md-7' : 'col-md-12'"
            style="gap: 15px">
            <div style="font-weight: 400; display: flex">
             {{ $t("modalDetail.Bienvenue")}}
         
            </div>
            <div class="logo-data" style="display: flex; align-items: center">
              <img width="130" class="logo-fact-img"                   :src="getImage('STA')"
 />
              <div    :class="[
          $i18n.locale === 'ar' || $i18n.locale === 'tn' ? 'logo-data-ar' : '',
        ]" class="logo-data-fact  data-design" style="border-left: 3px dashed #e0e0e0">
                <div 
                  class="responsive-table-title-data-all"
                  style="display: flex"
                >
                  <span class="responsive-table-title"
                    >{{ $t("modalDetail.Reference") }} :
                  </span>
                  <span
                    class="responsive-table-title-data"
                    style="margin-left: 5px">
                    {{ recharge?.rechargeRef }}
                  </span>
                </div>
                <div class="btn-data-responsive">
                  <button
                    type="button"
                    :class="getColorEtatRecharge(recharge?.paymentStatus)"

                    class="button-ar"
                    style="
                      text-transform: capitalize;
                      cursor: auto;
                      /* margin-left: 40%; */
                    "
                  >
                  {{ formatStatus(recharge?.paymentStatus) }}
                  </button>
                </div>
              </div>
            </div>
            <div v-if="recharge?.lastRechargeAmount && recharge?.lastRechargeDate">

            <div 
              v-for="(key, i) in rechargeKeys"
              :key="i"
              class="mt-2 detail-container"
              style="font-weight: 500">
              <span class="labelText">   {{ $t(`modalDetail.${key}`) }} :</span>
              <span style="text-transform: capitalize">
                {{ formatDetailRecharge[key] }}
              </span>
            </div>
            </div>
            <div
            v-if="!recharge?.lastRechargeAmount && !recharge?.lastRechargeDate"

              class="mt-2 detail-container"
              style="font-weight: 500"
            >
              <span class="labelText">   {{ $t(`modalDetail.solde`) }} :</span>
              <span style="text-transform: capitalize">
                {{getMontant(recharge?.rechargeAmount)}} {{$t("modalDetail.TND")  }}            </span>
            </div>
            <div

            v-if=" !recharge?.lastRechargeAmount && !recharge?.lastRechargeDate"

              style="
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 5px;
                justify-content: center;
                /* margin: 0; */
                padding: 0;
                margin: 25px 0px;
              "
            >
              <button
              @click="goToPro()"
              class="btn-res"
                style="font-size: 12px !important"
              >
                {{ $t("modalDetail.Creer") }}
              </button>
              <button
              @click="
                        () => {
                          this.moreDetailChargingDialog = false;
                          openCharging(this.recharge);
                        }
                      "
                class="btn-paid"
                style="font-size: 12px !important"
              >
                {{ $t("modalPanier.btnVoirdcc") }}
              </button>
        
            </div>
          </div>
        </div>
      </div>
       
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="afterPayDialog"
      width="80%"
      height="100%"
      overlay-color="white"
      overlay-opacity="0.5"
    >
      <v-card>
        <div
          style="
            display: flex;
            justify-content: space-evenly;
            align-items: center;
          "
          class="modal-title"
        >
          <img
            style="max-width: 200px; max-height: 100px"
            class="logo-fact"
            :src="getImage('STA')"
          />
          <p
            style="
              font-size: 40px;
              color: #337fa8;
              font-weight: 900;
              font-family: 'Source Sans Pro';
              text-align: center;
            "
            class="small-header pay-header"
          >
            {{ $t("modalDetail.rechdetails") }}
          </p>
          <img
            @click="afterPayDialog = false"
            class="close-modal-icon scale"
            src="@/assets/images/close-modal-icon.png"
          />
        </div>
        <v-card-text>
          <div class="container">
            <div class="row">
              <div
                class="col-md-5 d-flex flex-column align-items-center justify-content-center border border-1 rounded-3"
                style="padding: 0px 5px"
              >
                <img
                  class="receipt-img"
                  src="@/assets/receipt.png"
                  style="
                    width: 60%;
                    height: 52%;
                    box-shadow: 0px 2px 48px rgba(0, 0, 0, 0.04);
                  "
                />
                <button
                  @click="printRechargeRecu(recharge)"
                  style="
                    color: rgb(51, 127, 168);
                    border: 1px solid rgb(51, 127, 168);
                    margin-top: 40px;
                    margin-bottom: 20px;
                    margin-left: auto;
                    margin-right: auto;
                    width: 60% !important;
                  "
                >
                  {{ $t("imprimer") }}
                </button>
              </div>
              <div class="col-md-7" id="detailDialog">
                <div class="row align-items-center">
                  <h5>
                    {{ $t("modalDetail.Bienvenue") }}
                  </h5>
                  <div class="w-100"></div>
                  <div class="col-sm colColor fw-bold text-dark">
                    {{ $t("modalDetail.Reference") }}
                  </div>
                  <div class="col">
                    {{ recharge?.rechargeRef }}
                  </div>
                  <div class="w-100"></div>
                  <div class="col colColor fw-bold text-dark">
                    {{ $t("modalPanier.soldbeforerecharge") }}
                  </div>
                  <div class="col">
                    {{
                      (
                        getMontant(recharge?.rechargeAmount) -
                        getMontant(recharge?.lastRechargeAmount)
                      )?.toFixed(3) ?? 0
                    }}
                    {{ $t("modalPanier.TND") }}
                  </div>
                  <div class="w-100"></div>
                  <div class="col colColor fw-bold text-dark">
                    {{ $t("modalPanier.soldrecharge") }}
                  </div>
                  <div class="col">
                    {{ getMontant(recharge?.lastRechargeAmount) ?? 0 }}
                    {{ $t("modalPanier.TND") }}
                  </div>
                  <div class="w-100"></div>
                  <div class="col colColor fw-bold text-dark">
                    {{ $t("modalPanier.montantrecharge") }}
                  </div>
                  <div class="col">
                    {{ getMontant(recharge?.paidAmount) ?? 0 }}
                    {{ $t("modalPanier.TND") }}
                  </div>
                  <!-- <div class="w-100" ></div>
                  <div class="col colColor fw-bold text-dark">
                    {{ $t("modalPanier.montantDrech") }}
                  </div>
                  <div class="col">
                    {{  getMontant(this.rechargePayments.find(e => e.id === recharge.id)?.amount) ?? 0}}  
                    {{ $t("modalPanier.TND") }}
                  </div> -->
                  <div class="w-100"></div>
                  <div class="col colColor fw-bold text-dark">
                    {{ $t("modalPanier.dateDrech") }}
                  </div>
                  <div class="col">
                    {{ getPayTime(recharge?.lastRechargeDate) ?? null }}
                  </div>
                  <div class="w-100"></div>
                  <div class="col colColor fw-bold text-dark">
                    {{ $t("modalPanier.soldafterrecharge") }}
                  </div>
                  <div class="col">
                    {{ getMontant(recharge?.rechargeAmount) }}
                    {{ $t("modalPanier.TND") }}
                  </div>
                  <div
                    class="w-100"
                    v-if="recharge?.lastRechargeTransactionRef"
                  ></div>
                  <div
                    class="col colColor fw-bold text-dark"
                    v-if="recharge?.lastRechargeTransactionRef"
                  >
                    {{ $t("reftrans") }}
                  </div>
                  <div class="col" v-if="recharge?.lastRechargeTransactionRef">
                    {{ recharge?.lastRechargeTransactionRef ?? null }}
                  </div>
                  <div class="w-100" v-if="recharge?.lastRechargeMethod"></div>
                  <div
                    class="col colColor fw-bold text-dark"
                    v-if="recharge?.lastRechargeMethod"
                  >
                    {{ $t("solpaiement") }}
                  </div>
                  <div class="col" v-if="recharge?.lastRechargeMethod">
                    {{ recharge?.lastRechargeMethod ?? null }}
                  </div>
                  <div class="w-100"></div>
                  <div class="col colColor fw-bold text-dark">
                    {{ $t("modalDetail.statut") }}
                  </div>
                  <div class="col">
                    <div
                      class="col-sm custom-invoice-state invoice-details-state"
                      v-bind:style="
                        getColorEtatService(recharge?.paymentStatus)
                      "
                    >
                      {{ formatStatus(recharge?.paymentStatus) }}
                    </div>
                  </div>
                  <!-- <div
                    class="d-flex align-items-center justify-content-center mt-3 unshow"
                    style="gap: 10px"
                  >
                    <button @click="goToPro()" class="btn-turquoise">
                      {{ $t("modalDetail.Creer") }}
                    </button>
                    <button
                    @click="()=>{this.moreDetailChargingDialog=false;openCharging(this.recharge)}"
                      class="btn-turquoise-outline"
                    >
                    {{ $t("modalPanier.btnVoirdcc") }}
                    </button>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="addAccount"
      max-width="400"
      height="80%"
      overlay-color="white"
      overlay-opacity="0.5"
      :persistent="true"
    >
      <v-card>
        <img
          src="@/assets/all_new_images/sta.png"
          alt="sta"
          width="160"
          class="mx-auto d-block"
        />
        <h6
          style="
            font-size: 30px;
            color: #337fa8;
            font-weight: 900;
            font-family: 'Source Sans Pro';
            text-align: center;
            padding: 15px;
          "
          class="small-header pay-header"
        >
          {{ $t("modalConsultation.addAccount") }}
        </h6>
        <!-- <p class="text-muted text-center">
          {{ $t("modalConsultation.criteria") }}
        </p> -->
        <div
          style="
            display: flex;
            justify-content: space-evenly;
            align-items: center;
          "
          class="modal-title"
        >
          <img
            @click="
              addAccount = false;
              isSta = false;
              resetCapt();
              addAccountTopnet =false
              isSonede = true;
              isSteg = false;
              isTopnet = false;
            "
            class="close-modal-icon scale"
            src="@/assets/images/close-modal-icon.png"
          />
        </div>
        <v-card-text>
          <div
            class="d-flex align-items-center flex-column find-bills-fields"
            style="gap: 20px"
          >
             <!--    <div
              :class="[
                $i18n.locale === 'fr' || $i18n.locale === 'en'
                  ? 'me-4'
                  : 'ms-4',
              ]"
              style="border-radius: 5px"
            >
          <select
                v-model="search2"
                id="sonede-select"
                class="form-select charging-inputs"
                aria-label="Default select example"
                @change="getSelectType($event)"
              >
                <option value="clientCin" selected>
                  {{ $t("modalConsultation.clientCin") }}
                </option>
                <option value="clientMf">
                  {{ $t("modalConsultation.clientMf") }}
                </option>
              </select>
            </div> -->
            <!-- <div
              class="position-relative"
              :class="[
                $i18n.locale === 'fr' || $i18n.locale === 'en'
                  ? 'me-4'
                  : 'ms-4',
              ]"
              style="border-radius: 5px"
            >
              <input
                class="charging-inputs"
                :class="[abnRef1 && !notValidAbnRef1 && 'border border-danger']"
                id="abnRef1"
                type="text"
                v-model="abnRef1"
                :placeholder="
                  search2 === 'clientCin'
                    ? $t('modalConsultation.clientCinpl')
                    : search2 === 'clientMf' &&
                      $t('modalConsultation.clientMfpl')
                "
                @input="validInput()"
              />
              <span
                v-if="abnRef1 && !notValidAbnRef1"
                class="text-danger position-absolute top-ref"
                :class="[
                  $i18n.locale === 'fr' || $i18n.locale === 'en'
                    ? 'start-0'
                    : 'end-0',
                ]"
                style="font-size: 12px"
                >{{ errorAbnRef1 }}</span
              >
            </div> -->
            <div
              :class="[
                $i18n.locale === 'fr' || $i18n.locale === 'en'
                  ? 'me-4'
                  : 'ms-4',
              ]"
              style="border-radius: 5px"
            >
              <select
                v-model="search3"
                id="sonede-select"
                class="form-select charging-inputs"
                aria-label="Default select example"
                @change="getSelectType2($event)"
              >
                <option value="clientPhone" selected>
                  {{ $t("modalConsultation.clientPhone") }}
                </option>
                <option value="RechargeRef">
                  {{ $t("modalConsultation.RefAbnSTA") }}
                </option>
              </select>
            </div>
            <div
              class="position-relative"
              :class="[
                $i18n.locale === 'fr' || $i18n.locale === 'en'
                  ? 'me-4'
                  : 'ms-4',
              ]"
              style="border-radius: 5px"
            >
              <input
                class="charging-inputs"
                :class="[abnRef2 && !notValidAbnRef2 && 'border border-danger']"
                id="abnRef2"
                type="text"
                v-model="abnRef2"
                :placeholder="
                  search3 === 'RechargeRef'
                    ? $t('modalConsultation.RefAbnSTApl')
                    : search3 === 'clientPhone' &&
                      $t('modalConsultation.clientPhonepl')
                "
                @input="validInput()"
              />
              <span
                v-if="abnRef2 && !notValidAbnRef2"
                class="text-danger position-absolute top-ref"
                :class="[
                  $i18n.locale === 'fr' || $i18n.locale === 'en'
                    ? 'start-0'
                    : 'end-0',
                ]"
                style="font-size: 12px"
                >{{ errorAbnRef2 }}</span
              >
            </div>

            <vue-recaptcha
              :class="[
                $i18n.locale === 'fr' || $i18n.locale === 'en'
                  ? 'me-4'
                  : 'ms-4',
              ]"
              class="mt-3 small-captcha2"
              ref="recaptcha2"
              @expired="expiredCaptcha2"
              @verify="onVerify2"
              :sitekey="idCaptcha2"
            >
            </vue-recaptcha>
            <div
              :class="[
                $i18n.locale === 'fr' || $i18n.locale === 'en'
                  ? 'me-4'
                  : 'ms-4',
              ]"
            >
              <button
                class="btn-find my-2"
                :class="[
                  !(captcha2  && notValidAbnRef2) &&
                    'disable',
                ]"
                :disabled="!(captcha2  && notValidAbnRef2)"
                @click="goToDetail()"
              >
                {{ $t("modalConsultation.addAccount") }}
              </button>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>



    <v-dialog v-model="addAccountTopnet" width="450px" 
    overlay-color="white"
    overlay-opacity="0.5"
    :persistent="true"
     >
      <v-card>
        <div class="modal-title">
          <h6          style="
            font-size: 30px;
            color: #337fa8;
            font-weight: 900;
            font-family: 'Source Sans Pro';
            text-align: center;
            padding: 15px;
          "
          class="small-header pay-header">{{ $t("modalConsultation.AjoutFacture") }}</h6>
      
        <img @click="closeSearchtopnet();
          " class="close-modal-icon p-1" src="@/assets/images/close-modal-icon.png">
        </div>
        <v-card-text>
          <div :style="getStyle()">
              <div class="d-flex align-items-center justify-content-center m-4">
              <div class="btn border border-1 rounded-3 w-50 p-3 text-center" 
              :class="[isParticular && 'isactive', ($i18n.locale==='en' 
              || $i18n.locale==='fr')
               ? 'me-3':'ms-3']" @click="chooseClientType('particular')">
               {{ $t("modalConsultation.particular") }}</div>
               
              <div class="btn border border-1 rounded-3 w-50 p-3 text-center" 
              :class="[isCompany && 'isactive']" @click="chooseClientType('company')"
              >{{ $t("modalConsultation.company") }}</div>
              </div>
              <div v-if="isParticular" style="d-flex flex-column">
                <p class="text-muted text-center">
          {{ $t("modalConsultation.criteria") }}
        </p>
                <select id="part-ref-sel" class="form-select mx-auto mb-6"
                 aria-label="Default select example"
                 v-model="search4" @change="getSelectTypeTopnet($event)"
                 :style="{ 'background-position':($i18n.locale === 'ar' 
                 || $i18n.locale === 'tn')
                  && 'left 0.75rem center' }"
                 >
                  <option value="contractNumber">
                    {{ $t("modalConsultation.clientcontract") }}
                    </option>
                  <option value="invoiceRef1">
                    {{ $t("modalConsultation.referenceF") }}
                    </option>
                  <option value="clientCode">
                    {{ $t("modalConsultation.clientcode") }}
                    </option>
                </select>
                <div     class="position-relative"
              :class="[
                $i18n.locale === 'fr' || $i18n.locale === 'en'
                  ? 'me-4'
                  : 'ms-4',
              ]"
              style="border-radius: 5px ;margin-bottom: 25px; width: 100% !important">
                 <input 

                class="topnet-inputs"
                :class="[invoiceRef1 && !notValidAbnRef1 && 'border border-danger']"

                v-model="invoiceRef1" 
                type="text" 
                :placeholder=" search4 === 'clientCode'
                    ? $t('modalConsultation.clientcode')
                    : search4 === 'contractNumber'
                    ? $t('modalConsultation.clientcontract')
                    : $t('modalConsultation.referenceF')
                "
                 @input="validInput()"

   
                />
                <span
                v-if="invoiceRef1 && !notValidAbnRef1"
                class="text-danger position-absolute top-ref"
                :class="[
                  $i18n.locale === 'fr' || $i18n.locale === 'en'
                    ? 'start-0'
                    : 'end-0',
                ]"
                style="font-size: 12px"
                >{{ errorAbnRef1 }}</span
              >
              </div>
                <select id="part-ref-sel-id" class="form-select mx-auto mb-6"
                 aria-label="Default select example"
                 v-model="search2" @change="getSelectType2Topnet($event)"
                 :style="{ 'background-position':($i18n.locale === 'ar' 
                 || $i18n.locale === 'tn')
                  && 'left 0.75rem center' }"
                 >
                  <option value="clientCin" selected>
                    {{ $t("modalConsultation.clientCin") }}
                    </option>
                  <option value="clientPassport">
                    {{ $t("modalConsultation.clientpass") }}
                    </option>
                  <option value="clientCs">
                    {{ $t("modalConsultation.clientres") }}
                    </option>
                </select>
                <div
              class="position-relative"
              :class="[
                $i18n.locale === 'fr' || $i18n.locale === 'en'
                  ? 'me-4'
                  : 'ms-4',
              ]"
              style="border-radius: 5px; width: 100% !important; margin-bottom: 25px;"
            >
                <input 
                class="topnet-inputs"
                :class="[clientCin && !notValidAbnRef2 && 'border border-danger']"
                id="clientCin"
                v-model="clientCin"
                type="text" 
                :placeholder="
                search2 === 'clientCin'
                ? $t('modalConsultation.clientCin')
                : search2 === 'clientPassport'
                ? $t('modalConsultation.clientpass'):
                $t('modalConsultation.clientres')
                "
            
            @input="validInput()"

                
              /> 
              <span
                v-if="clientCin && !notValidAbnRef2"
                class="text-danger position-absolute top-ref"
                :class="[
                  $i18n.locale === 'fr' || $i18n.locale === 'en'
                    ? 'start-0'
                    : 'end-0',
                ]"
                style="font-size: 12px"
                >{{ errorAbnRef2 }}</span
              >
              </div>
              </div>
               <div v-else-if="isCompany" style="d-flex flex-column">
                <p class="text-muted text-center">
          {{ $t("modalConsultation.criteria") }}
        </p>
                <select id="comp-ref-sel" class="form-select mx-auto mb-6"
                 aria-label="Default select example" v-model="search4" 
                 @change="getSelectTypeTopnet($event)"
                 :style="{ 'background-position':($i18n.locale === 'ar' 
                 || $i18n.locale === 'tn')
                  ? 'left 0.75rem center': 'right 0.75rem center'}"
                 >
                  <option value="contractNumber">
                    {{ $t("modalConsultation.clientcontract") }}
                    </option>
                  <option value="invoiceRef1">
                    {{ $t("modalConsultation.referenceF") }}
                    </option>
                  <option value="clientCode">
                    {{ $t("modalConsultation.clientcode") }}
                    </option>
                </select>
                <!-- <v-text-field id="comp-ref" class="mx-auto"
                v-model="invoiceRef" type="text" 
                :label="
                search === 'clientCode'
                ? $t('modalConsultation.clientcode')
                :search === 'contractNumber'
                ? $t('modalConsultation.clientcontract')
                : $t('modalConsultation.referenceF')
                "
                :rules="search === 'clientCode'
                    ? [
                v => !!v || $t('modalConsultation.requiredField'),
                v => v.length <= 13 || $t('modalConsultation.invalidField'),
                v =>  /^[A-Z]{1}-\d{4}-\d{6}$/gm.test(v) 
                || $t('modalConsultation.invalidField'),
              ]
                    : search === 'contractNumber'
                    ? [
                v => !!v || $t('modalConsultation.requiredField'),
                v => v.length <= 8 || $t('modalConsultation.invalidField'),
                v =>  /^\d{8}$/gm.test(v) || $t('modalConsultation.invalidField'),
              ]
                    : [
                v => !!v || $t('modalConsultation.invalidField'),
                v => v.length <= 11 || $t('modalConsultation.invalidField'),
                v =>  /^\d{11}$/gm.test(v) || $t('modalConsultation.invalidField'),
              ]"
                outlined
                dense
                style="width:90%"
                > 
                </v-text-field>-->
                <div     class="position-relative"
              :class="[
                $i18n.locale === 'fr' || $i18n.locale === 'en'
                  ? 'me-4'
                  : 'ms-4',
              ]"
              style="border-radius: 5px ;margin-bottom: 35px; width: 100% !important">
                  <input 

                class="topnet-inputs"
                :class="[invoiceRef1 && !notValidAbnRef1 && 'border border-danger']"

                v-model="invoiceRef1" 
                type="text" 
                :placeholder=" search4 === 'clientCode'
                    ? $t('modalConsultation.clientcode')
                    : search4 === 'contractNumber'
                    ? $t('modalConsultation.clientcontract')
                    : $t('modalConsultation.referenceF')
                "
                 @input="validInput()"

   
                />
                <span
                v-if="invoiceRef1 && !notValidAbnRef1"
                class="text-danger position-absolute top-ref"
                :class="[
                  $i18n.locale === 'fr' || $i18n.locale === 'en'
                    ? 'start-0'
                    : 'end-0',
                ]"
                style="font-size: 12px"
                >{{ errorAbnRef1 }}</span
              >
              </div>
                <!-- <v-text-field class="mx-auto"
                v-model="clientMF" type="text" 
                :label="$t('modalConsultation.matriculefiscale')"
                :rules="[
                v => !!v || $t('modalConsultation.requiredField'),
                v => v.length <= 20 || $t('modalConsultation.invalidField'),
                v => /^[A-Za-z0-9/,\,-]*$/gm.test(v) || $t('modalConsultation.invalidField')
                ]"
                outlined
                dense
                style="width:90%"
                >
                </v-text-field> -->
                <div
              class="position-relative"
              :class="[
                $i18n.locale === 'fr' || $i18n.locale === 'en'
                  ? 'me-4'
                  : 'ms-4',
              ]"
              style="border-radius: 5px; width: 100% !important; margin-bottom: 25px;"
            >
                <input 
                class="topnet-inputs"
                :class="[clientCin && !notValidAbnRef2 && 'border border-danger']"
                id="clientCin"
                v-model="clientCin"
                type="text" 
                :placeholder=" $t('modalConsultation.clientMf')"
            
            @input="validInput()"

                
              /> 

              <span
                v-if="clientCin && !notValidAbnRef2"
                class="text-danger position-absolute top-ref"
                :class="[
                  $i18n.locale === 'fr' || $i18n.locale === 'en'
                    ? 'start-0'
                    : 'end-0',
                ]"
                style="font-size: 12px"
                >{{ errorAbnRef2 }}</span
              >
              </div>
              </div>
              <div 
              >
                <div class="d-flex align-items-center justify-content-center mb-4">
                  <vue-recaptcha 
                  ref="recaptcha" @expired="expiredCaptcha" 
                  @verify="onVerify" :sitekey="idCaptcha">
                  </vue-recaptcha>
                </div>
              </div>
             <div class="d-flex align-items-center justify-content-center">
              <button 
              :class="[
                  !(this.captcha && notValidAbnRef1 && notValidAbnRef2) &&
                    'disable',
                ]"
                :disabled="!(this.captcha && notValidAbnRef1 && notValidAbnRef2)"
            
              
             @click="goToDetail()" 
              class="btn-find my-2"
>
            <img src="@/assets/images/btnIcon.png" style="width: 12px; margin-right: 5px" />{{
            $t("modalConsultation.rechercher") }}
              </button>
             </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>



    <loader
      v-if="load"
      object="#1999C2"
      color1="#ffffff"
      color2="#17fd3d"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#ffffff"
      opacity="80"
      name="spinning"
    ></loader>
  </div>
</template>
<script>
import NotificationBell from "vue-notification-bell";
import VueRecaptcha from "vue-recaptcha";
//import PaimentModal from "../components/paiment/PaimentModal.vue";
import { mapActions, mapGetters } from "vuex";
//import Vuetable from "vuetable-2";
//import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableFieldHandle from "vuetable-2/src/components/VuetableFieldHandle.vue";
// import BillDetailExpress from "./DetailPage/BillDetailExpress.vue";
import _ from "lodash";
import { jsPDF } from "jspdf";
import Swal from "sweetalert2";
import { font } from "../utils/font";
const CODE_PAYSMART = process.env.VUE_APP_PAYSMART;
const Cnte_Recu = process.env.VUE_APP_CNTE_RECEIPT

export default {
  components: { VueRecaptcha, NotificationBell },

  computed: {
    ...mapGetters([
      "cards",
      "service",
      "services",
      "invoices",
      "invoice",
      "searchbills",
      "recharges",
      "recharge",
      "rechargePayments",
      "paymentRechargeId",
      "searchrechargs",
      "getInvoices",
      "getError",
      "getNewInvoices",
      "getSubCategory",
    ]),

    options() {
      return [
        {
          title: "steg",
          cardImage: require("@/assets/images/partner/steg.png"),
          billet: 701,
          text: this.$t("modalConsultation.RefAbn"),
        },
        {
          title: "sonede",
          cardImage: require("@/assets/images/partner/sonde.png"),
          billet: 502,
          text: this.$t("modalConsultation.contract"),
        },
        {
          title: "sta",
          cardImage: require("@/assets/images/partner/sta.png"),
          billet: "003",
          text: this.$t("modalConsultation.RefAbn"),
        },
        {
          title: "cnte",
          cardImage: require("@/assets/images/partner/CNTE.png"),
          billet: "004",
          text: this.$t("modalConsultation.idUnique"),
        },
        {
          title: "topnet",
          cardImage: require("@/assets/images/TOPNET.png"),
          billet: "600",
          text: this.$t("modalConsultation.idUnique"),
        },

      ];
    },
    validate: {
      get() {
        return this.validateForm && this.captcha;
      },
      set(value) {
        this.validateForm = value;
      },
    },
    invoiceKeys   () {
      const bill =  this?.invoiceData
      return (
        Object.keys(this.formatDetail)
          .filter((el) =>
          bill?.invoicePreiod?.fromDate === null
              ? el != "Période" && this.formatDetail[el] != ""
              : this.formatDetail[el] != ""
          )
      );
    },


    formatDetail() {
      const bill = this?.invoiceData;
      console.log("faten1",this.$t("modalDetail.TND"))
      if (!(this.getDate(bill?.invoicePreiod?.fromDate) && this.getDate(bill?.invoicePreiod?.toDate))) {
        return {

          Numéroducontrat: bill?.contractNumber,
          dateEmi: this.getDate(bill?.invoiceDate),
          dateEch: this.getDate(bill?.invoiceExpiryDate),
          montantF: (bill?.montantFacture / 1000).toFixed(3) + this.$t("modalDetail.TND"),
          montantP: bill?.isPaid
            ? "0" + this.$t("modalDetail.TND")
            : (bill?.montantFacture / 1000).toFixed(3) + this.$t("modalDetail.TND"),
           adresse: bill?.clientAddress
    
        };
      } 
      else if(bill.biller == '600'){
        const formattedDetail = {
          clientcode: bill?.clientCode,
          clientcontract: bill?.contractNumber,
          dateEmi: this.getDate(bill?.invoiceDate),
          dateEch: this.getDate(bill?.invoiceExpiryDate),
          montantF: (bill?.invoiceOriginalAmount / 1000).toFixed(3) + this.$t("modalDetail.TND"),
          montantP: bill?.paymentStatus.toLowerCase() === "paid"
            ? "0" + this.$t("modalDetail.TND")
            : (bill?.invoiceOriginalAmount / 1000).toFixed(3) + this.$t("modalDetail.TND"),
    

          periode:
            this.$t("modalDetail.du") +
            " " +
            this.getDate(bill?.invoicePreiod?.fromDate) +
            " " +
            this.$t("modalDetail.au") +
            " " +
            this.getDate(bill?.invoicePreiod?.toDate),
        }
        if (bill?.paymentsList[0]?.transactionReference !== null &&  bill?.paymentsList[0]?.transactionReference !== undefined ) {
      formattedDetail.reftrans = bill?.paymentsList[0]?.transactionReference;
    }
        return formattedDetail;

      }
      else{
      const formattedDetail =  {

          Numéroducontrat: bill?.contractNumber,
          dateEmi: this.getDate(bill?.invoiceDate),
          dateEch: this.getDate(bill?.invoiceExpiryDate),
          montantF: (bill?.invoiceOriginalAmount / 1000).toFixed(3) + this.$t("modalDetail.TND"),
          montantP: bill?.paymentStatus.toLowerCase() === "paid"
            ? "0" + this.$t("modalDetail.TND")
            : (bill?.invoiceOriginalAmount / 1000).toFixed(3) + this.$t("modalDetail.TND"),
    

          periode:
            this.$t("modalDetail.du") +
            " " +
            this.getDate(bill?.invoicePreiod?.fromDate) +
            " " +
            this.$t("modalDetail.au") +
            " " +
            this.getDate(bill?.invoicePreiod?.toDate),
            adresse: bill?.clientAddress,
        }
        if (bill?.paymentsList[0]?.transactionReference !== null &&  bill?.paymentsList[0]?.transactionReference !== undefined ) {
      formattedDetail.reftrans = bill?.paymentsList[0]?.transactionReference;
    }
    return formattedDetail;
      }
    },

    rechargeKeys() {
      return (
        Object.keys(this.formatDetailRecharge)
          .filter((el) =>
          this.formatDetailRecharge[el] != ""
          )
      );
    },
    formatDetailRecharge() {
      const recharge = this.recharge;
      let newCharge = {
        // cidentite:charge.clientCin,
        // mfiscale:charge.clientMf.length!==0 ? charge.clientMf:'- - - - - - - ',
        // codeclient: charge.clientCode,
        // numtel:this.formatJson(charge?.clientPhones),
        solde :  this.getMontant(recharge?.rechargeAmount)+ " " + this.$t("modalDetail.TND"),
        montantC:this.getMontant(recharge?.amountToRecharge) + " " + this.$t("modalDetail.TND"),
        montantP: this.getMontant(recharge?.amountToPay)+ " " + this.$t("modalDetail.TND"),
        }
        let detailRecharge = {
          solde :  this.getMontant(recharge?.rechargeAmount)+ " " + this.$t("modalDetail.TND"),
          montantDrech : (this.getMontant(recharge?.lastRechargeAmount) ?? 0) + " " + this.$t("modalDetail.TND"),
          dateDrech : this.getPayTime(recharge?.lastRechargeDate) ?? null,
          reftrans : recharge?.lastRechargeTransactionRef ?? null,
          solpaiement : recharge?.lastRechargeMethod ?? null
        }
       if(this.moreDetailChargingDialog)return detailRecharge
       else return newCharge
    },

    
    serviceKeys() {
    return Object.keys(this.formatDetailService).filter(
      (el) => this.formatDetailService[el] != ""
       );
        },

        formatDetailService() {
      const service = this?.service;

      if (service && service?.paymentStatus) {
        const paymentStatusLowerCase = service?.paymentStatus.toLowerCase();

        if (paymentStatusLowerCase == 'paid') {
          return {
            montantPa: (service?.objectOriginalAmount / 1000).toFixed(3) + " " + this.$t("modalDetail.TND"),
            anneeScolaie: service?.objectDate,
          };
        } else {
          return {
            montantP: (service?.objectOriginalAmount / 1000).toFixed(3) + " " + this.$t("modalDetail.TND"),
            anneeScolaie: service?.objectDate,
          };
        }
      } else {
        console.error("Le service ou paymentStatus n'est pas défini.");
        return {};
      }
    },
    headers() {
      return [
        {
          text: this.$t("modalPanier.dateEch"),
          align: "center",
          value: "invoiceExpiryDate",
        },
        {
          text: this.$t("modalPanier.Facturier"),
          align: "center",
          value: "billerRs",
        },
        {
          text: this.$t("modalPanier.Reference"),
          align: "center",
          value: "invoiceRef",
        },
        {
          text: this.$t("modalPanier.Montant"),
          align: "center",
          value: "invoiceAmount",
        },
        {
          text: this.$t("modalPanier.Etat"),
          align: "center",
          value: "paymentStatus",
        },
        {
          text: this.$t("modalPanier.actions"),
          align: "center",
          value: "Actions",
        },
      ];
    },
    headersServices() {
      return [
        {
          text: this.$t("modalPanier.fournisseur"),
          align: "center",
          value: "billerRs",
        },
        {
          text: this.$t("modalPanier.Identifient"),
          align: "center",
          value: "objectRef",
        },
        {
          text: this.$t("modalPanier.MontantTND") ,
          align: "center",
          value: "objectOriginalAmount",
        },
        {
          text: this.$t("modalPanier.Etat"),
          align: "center",
          value: "paymentStatus",
        },
        {
          text: this.$t("modalPanier.actions"),
          align: "center",
          value: "Actions",
        },
      ];
    },
    chargingHeaders() {
      return [
        {
          text: this.$t("modalPanier.dateCons"),
          align: "center",
          value: "rechargeDate",
        },
        {
          text: this.$t("modalPanier.service"),
          align: "center",
          value: "billerRs",
        },
        {
          text: this.$t("modalPanier.Reference"),
          align: "center",
          value: "rechargeRef",
        },
        {
          text: this.$t("modalPanier.solde"),
          align: "center",
          value: "rechargeAmount",
        },
        {
          text: this.$t("modalPanier.Etat"),
          align: "center",
          value: "paymentStatus",
        },
        {
          text: this.$t("modalPanier.actions"),
          align: "center",
          value: "Actions",
        },
      ];
    },
  },
  data() {
    return {
      Captcha:"",
      payDate: "",
      path: window.location.pathname,
      showBurger: false,
      isCnte: false,
      isSonede: true,
      isSteg: false,
      isSta: false,
      isTopnet:false,
      isCompany:false,
      isParticular:true,
      isBill: true,
      isServices: false,
      isCharging: false,
      display: true,
      Click_To_Pay: process.env.VUE_APP_CLICKTOPAY,
      loginUrl: process.env.VUE_APP_LOGIN_URL,
      maxsearchnbr: process.env.VUE_APP_SEARCH_NBR || 200,
      maxsearchtmp: process.env.VUE_APP_SEARCH_TMP || 1,
      google_play: process.env.VUE_APP_PAYSMART_MOBILE_GOOGLE,
      apple_store: process.env.VUE_APP_PAYSMART_MOBILE_APPLE,
      defaultSelected: {
        title: "sonede",
        cardImage: " @/assets/images/partner/sonde.png",
        billet: 502,
        text: this.$t("modalConsultation.contract"),
      },
      addAccount: false,
      addAccountTopnet:false,
      rechargeDialog: false,
      moreDetailChargingDialog: false,
      afterPayDialog: false,
      payDialog: false,
      load: false,
      showMobileMenu: false,
      detailDialog: false,
      detailDialogService: false,
      dialog: false,
      desc: false,
      active: true,
      searchDealg: false,
      search: "contractNumber",
      search2: "clientCin",
      search3: "clientPhone",
      search4:"contractNumber",
      invoiceRef: "",
      invoiceCnte: "",

      invoiceRef1:"",
      clientCin:"",
      clientMF:"",
      contractNumber:"",
      clientPassport:"",
      clientCs:"",
      abnRef1: "",
      abnRef2: "",
      footerProps: {
        showFirstLastPage: true,
        showCurrentPage: true,
        itemsPerPageText: "Eléments par page",
        itemsPerPageAllText: "Tous",
        pageText: "",
        itemsPerPageOptions: [5, 10, 20, 50, 100, 200, -1],
      },
      price: null,
      otherPrice: null,
      min: 10,
      step: 10,
      fields: [
        {
          name: VuetableFieldHandle,
        },
        {
          name: "invoiceDate",
          title: "Date de création",
          sortField: "invoiceDate",
        },
        {
          name: "invoiceExpiryDate",
          title: "Date échéance",
          sortField: "invoiceExpiryDate",
        },

        {
          name: "billerRs",
          title: "Facturier",
          sortField: "billerRs",
        },
        {
          name: "invoiceRef",
          title: "Référence",
          sortField: "invoiceRef",
        },

        {
          name: "invoiceAmount",
          title: "Montant",
          sortField: "invoiceAmount",
        },
        {
          name: "paymentStatus",
          title: "Etat",
          sortField: "paymentStatus",
        },
        "actions",
      ],
      idCaptcha: process.env.VUE_APP_RECAPTCHA_KEY,
      idCaptcha2: process.env.VUE_APP_RECAPTCHA_KEY,
      idCaptcha3: process.env.VUE_APP_RECAPTCHA_KEY,

      desserts: [],
      chargingDesserts: [],
      serviceDesserts: [],
      captcha: false,
      captcha2: false,
      captcha3: false,
      validateForm: true,
      qrcode: "Hello World!",
      qrcodeService:"",
      isSticky: false,
      isHidden: false,
      image: require("@/assets/header/logos/shopping-cart.png"),
      hi: "hola",
      largo: 200,
      toggle: false,
      isCard: false,
      invoiceData: [],
      rechargeData: {},
      notValidRef: false,
      errorRef: "",
      errorRefCnte:"",
      notValidRefCnte: false,

      notValidAbnRef1: false,
      errorAbnRef1: "",
      notValidAbnRef2: false,
      errorAbnRef2: "",
      detailChargingDialog: false,
    };
  },
  watch: {
    data(newVal, oldVal) {
      console.log(newVal + oldVal);
      this.$refs.vuetable.refresh();
    },
    cards: {
      deep: true,
      handler() {
        this.invoiceData = localStorage.getItem("invoices")
          ? JSON.parse(localStorage.getItem("invoices"))
          : [];
        this.desserts = this.invoiceData;
      },
    },
    invoices: {
      deep: true,
      handler() {
        this.invoiceData = localStorage.getItem("invoices")
          ? JSON.parse(localStorage.getItem("invoices")).filter(
              (el) => el.id === this.invoice?.id
            )[0]
          : {};
        this.desserts = localStorage.getItem("invoices")
          ? JSON.parse(localStorage.getItem("invoices"))
          : [];
      },
    },
    services: {
      deep: true,
      handler() {
        this.serviceDesserts = localStorage.getItem("services")
          ? JSON.parse(localStorage.getItem("services"))
          : [];
      },
    },
    recharges: {
      deep: true,
      handler() {
        this.chargingDesserts = localStorage.getItem("recharges")
          ? JSON.parse(localStorage.getItem("recharges"))
          : [];
        if (this.recharge?.isRecharged) {
          this.afterPayDialog = true;
        }
      },
    },
  },
  mounted() {
    window.addEventListener("scroll", () => {
      const scrollPos = window.scrollY;
      if (scrollPos >= 200) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
    });
  },
  created() {
    this.chargingDesserts = localStorage.getItem("recharges")
      ? JSON.parse(localStorage.getItem("recharges"))
      : [];
    this.desserts = localStorage.getItem("invoices")
      ? JSON.parse(localStorage.getItem("invoices"))
      : [];
    this.serviceDesserts = localStorage.getItem("services")
      ? JSON.parse(localStorage.getItem("services"))
      : [];
    console.log("localllllllll", localStorage);
  },
  methods: {
    // hideRechargeModal(){
    //   let rech = this.chargingDesserts?.find(e=>e?.id === this.recharge?.id)
    // },
    getStyle() {
      const local = this.$root.$i18n.locale;
      if  (local === "ar" || local === 'tn' ){
      import("@/assets/scss/_ar_.scss");
      }
    },
    resetOut(){

    this.search4="contractNumber";
    this.search2="clientCin"

    this.invoiceRef1 = ""
    this.clientCode = ""
    this.clientCin = ""
    this.clientMF = ""
    this.$refs.recaptcha.reset();
    this.captcha=false
    },
    chooseClientType(type){
    switch (type) {
      case 'company':
        this.isCompany=true
        this.isParticular=false
        this.search4 = "contractNumber"
        this.search2= "clientMf"  

        this.invoiceRef1='',
        this.clientCin =''
        this.clientCode = ""
        this.clientMF = "" 
        this.resetCapt()   
      break;
      case 'particular':
        this.isParticular=true
        this.isCompany=false
        this.search4 = "contractNumber"
        this.search2="clientCin" 
        this.invoiceRef1=''
        this.clientCin =''
        this.clientCode = ""
        this.clientMF = "" 
        this.resetCapt()   
  
        break;
      default:
        break;
    }
    // this.reset()
    // this.search4="contractNumber"
    // this.search2="clientCin"
    this.$refs.recaptcha.reset();
    this.captcha=false
    },
    changePrice(e) {
      this.price = e.target.value;
      this.otherPrice = this.price * 1000;
      console.log("priceeeeeeeeeee", this.price, this.otherPrice);
    },
    setPrice() {
      let type = this.recharge?.subType;
      if (type) {
        let str = type.split("-");
        // this.price=Number(str[1])
        switch (str[0]) {
          case "S":
            return {
              price: Number(str[1]) / 1000,
              min: Number(str[1]) / 1000,
              max: 100,
              step: 10,
            };
          case "C":
            return {
              price: Number(str[1]) / 1000,
              min: Number(str[1]) / 1000,
              max: 1000,
              step: 100,
            };
          case "N":
            return {
              price: Number(str[1]) / 1000,
              min: Number(str[1]) / 1000,
              max: 100,
              step: 10,
            };
          default:
            return {
              price: Number(str[1]) / 1000,
              min: Number(str[1]) / 1000,
              max: 100,
              step: 10,
            };
        }
      } else {
        return {
          price: 10,
          min: 10,
          max: 100,
          step: 10,
        };
      }
    },
    async rechargeWithCard() {
      this.detailChargingDialog = false;
      this.load = true;
      await this.confirmCharging({
        id: this.recharge?.paymentId,
        method: "C",
        paymentCanal: "C01",
        pan:"*****",
      })
        .then((res) => {
          console.log(res);
          this.load = false;
        })
        .catch((err) => {
          console.log(err);
          return Swal.fire({
            icon: "error",
            text: this.$t("modalDetail.errclickToPay"),
            confirmButtonColor: "#1db7c2",
            confirmButtonText: this.$t("modalDetail.Fermer"),
          });
        });
    },
    formatDate(value) {
      if (value)
        return (
          value.slice(0, 10).split("-").reverse().join("/") +
          " - " +
          value.slice(11, 16)
        );
      else return null;
    },
    closeChargeDetail() {
      this.detailChargingDialog = false;
    },
    async openChargingDetail(item) {
      this.load = true;

  

      await this.fetchRechargeByRef({
        RechargeRef: item?.rechargeRef,
        biller: this.options[2].billet,
      }).then((res) => {
        console.log("res1111xxx" , res);
        if(res){
        this.load = false;
        this.afterPayDialog = false;
        this.moreDetailChargingDialog = true;}
        else {
          this.load = false;
          this.$toast.error(this.$t("prbServeurSta"), {
            canTimeout: true,
            duration: 3000,
          });        }
      });
    },
    async openChargingCharge() {
      let rech = this.chargingDesserts?.find(
        (e) => e?.id === this.recharge?.id
      );
      console.log("rechhhhhhhhhhhhhh", rech);
      this.load = true;
      const done = await this.getRechargeAmount({
        recharge: rech,
        obj: {
          paymentItemsId: [rech?.id],
          paymentId: rech?.paymentId ? rech?.paymentId :  rech?.id,
          paymentMean: this.Click_To_Pay,
          paymentCanal: "C01",
          amount: this.price ? this.price * 1000 : this.setPrice().price * 1000,
        },
      });
      console.log("donexx",done)
      if(done){
      this.load = false;
      this.price = null;
      this.detailChargingDialog = true;}
      else {
        this.load = false;

      }
    },
    async openCharging(item) {
      // console.log("openCharg", item)
      this.load = true;
      await this.fetchRechargeByRef({
        RechargeRef: item?.rechargeRef,
        biller: this.options[2].billet,
      }).then((res) => {
        console.log("res" + res);
        if(res){

        this.load = false;
        this.afterPayDialog = false;
        this.rechargeDialog = true;
        } else {
          this.load = false;
          this.$toast.error(this.$t("prbServeurSta"), {
            canTimeout: true,
            duration: 3000,
          });

        }
      });

      // this.getRecharge(this.chargingDesserts.find(e=>e.id===item.id))
      // this.rechargeData = this.chargingDesserts.find(e=>e.id === item.id)
    },
    selectService(type) {
      console.log("type", type);
      if (type === "isBill") {
        this.isBill = true;
        this.isCharging = false;
        this.isServices = false;
      } else if (type == "isServices") {
        this.isBill = false;
        this.isCharging = false;
        this.isServices = true;
      } else if (type == "isCharging") {
        this.isBill = false;
        this.isCharging = true;
        this.isServices = false;
      }
      console.log(
        "isBill isCharging isServices",
        this.isBill,
        this.isCharging,
        this.isServices
      );
    },
    getPayTime(date) {
      var dt = new Date(date);
      const dateenc =
        dt.toLocaleDateString("fr", {
          timeZone: "Africa/Tunis",
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        }) +
        " - " +
        dt.toLocaleTimeString("fr", {
          timeZone: "Africa/Tunis",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        });
      return dateenc;
    },
    async printRecu(invoiceData) {
      let x = 20;
      let y = 20;
      let x_center = 105;
      let lMargin = 10; //left margin in mm
      let rMargin = 10; //right margin in mm
      let pdfInMM = 210; // width of A4 in mm
      let pdfHInMM = 300; // width of A4 in mm
      var nb;

      let newInv = await this.printInvoice(invoiceData);
      // if(newInv){

        console.log('solutiondata', newInv)
      // }
      if (
        newInv !== undefined &&
        newInv.paymentStatus.toLowerCase() === "paid"
      ) {
        let sol = await this.addToPrint(
          newInv.paymentsList.find(
            (e) => e.transactionStatus.toLowerCase() === "paid"
          ).paymentMean
        );
        console.log("solution", sol);
        const doc = new jsPDF();
        let img = new Image();
        let img2 = new Image();
        img.src = require("@/assets/paysmartLogoM.png");
        // img2.src = require("@/assets/header/logos/mognetique.png")
        img2.src = require("@/assets/header/logos/monettique.png");

        doc.addFileToVFS("Amiri-Regular-normal.ttf", font);
        doc.addFont("Amiri-Regular-normal.ttf", "Amiri-Regular", "normal");

        doc.addImage(img, "PNG", 7, 9, 30, 13);
        doc.setFontSize(10.5);
        doc.setTextColor(107, 100, 93);
        doc.setDrawColor(93, 93, 93);
        doc.setLineWidth(0.5);
        doc.line(10, 25, pdfInMM - 10, 25); // horizontal line
        doc.text(200, y, this.getPayTime(new Date()), "right");
        doc.setFontSize(24);
        doc.setTextColor(0, 0, 0);
        doc.setFont("Amiri-Regular", "bold");
        doc.text(x_center, y, `Réçu de paiement`, "center");
        doc.setFontSize(12);
        doc.setTextColor(0, 0, 0);
        doc.setFont("Amiri-Regular", "bold");
        doc.text(x, y + 15, "Référence de paiement: ", "left");
        doc.setFont("Amiri-Regular", "light");
        doc.text(x_center, y + 15, newInv.transactionReference, "left");
        doc.setFont("Amiri-Regular", "bold");
        doc.text(x, 2 * y + 5, `Facturier:`, "left");
        doc.setFont("Amiri-Regular", "bold");
        doc.text(x_center, 2 * y + 5, newInv.billerRs, "left");
        doc.setFont("Amiri-Regular", "bold");

        doc.text(x, 2 * y + 15, `Nom et Prénom:`, "left");
        doc.setFont("Amiri-Regular", "normal");

        doc.text(
          x_center,
          2 * y + 15,
          newInv?.clientName?.length > 0 ?  
            newInv?.clientLastname +
              " " +
              newInv?.clientName :  newInv?.clientLastname,
          "left"
        );
        doc.setFont("Amiri-Regular", "bold");
        doc.text(x, 3 * y + 5, `Référence de la facture:`, "left");
        doc.setFont("Amiri-Regular", "light");
        doc.text(x_center, 3 * y + 5, newInv.invoiceRef, "left");
        doc.setFont("Amiri-Regular", "bold");
        doc.text(x, 3 * y + 15, `Date d'émission:`, "left");
        doc.setFont("Amiri-Regular", "light");
        doc.text(
          x_center,
          3 * y + 15,
          this.getDate(newInv.invoiceDate),
          "left"
        );
        doc.setFont("Amiri-Regular", "bold");
        doc.text(x, 4 * y + 5, `Date de début de facturation:`, "left");
        doc.setFont("Amiri-Regular", "light");
        doc.text(
          x_center,
          4 * y + 5,
          this.getDate(newInv.invoicePreiod.fromDate),
          "left"
        );
        doc.setFont("Amiri-Regular", "bold");
        doc.text(x, 4 * y + 15, `Date de fin de facturation:`, "left");
        doc.setFont("Amiri-Regular", "light");
        doc.text(
          x_center,
          4 * y + 15,
          this.getDate(newInv.invoicePreiod.toDate),
          "left"
        );
        doc.setFont("Amiri-Regular", "bold");
        doc.text(x, 5 * y + 5, `Montant:`, "left");
        doc.setFont("Amiri-Regular", "light");
        doc.text(
          x_center,
          5 * y + 5,
          this.getMontant(newInv.dataAmount) + " TND",
          "left"
        );
        doc.setFont("Amiri-Regular", "bold");
        doc.text(x, 5 * y + 15, `Statut:`, "left");
        doc.setFont("Amiri-Regular", "light");
        doc.text(
          x_center,
          5 * y + 15,
          newInv.paymentStatus.toLowerCase() === "paid" && "Payée",
          "left"
        );
        doc.setFont("Amiri-Regular", "bold");
        doc.text(x, 6 * y + 5, "Date et heure de paiement: ", "left");
        doc.setFont("Amiri-Regular", "light");
        doc.text(x_center, 6 * y + 5, this.getPayTime(newInv.payDate), "left");
        // doc.setFont("Amiri-Regular", "bold");
        // doc.text(x, 6 * y + 5, "Canal de paiement: ", "left");
        // doc.setFont("Amiri-Regular", "light");
        // doc.text(
        //   x_center,
        //   6 * y + 5,
        //   newInv.paymentCanal.charAt(0).toUpperCase() + newInv.paymentCanal.slice(1),
        //   "left"
        // );
        doc.setFont("Amiri-Regular", "bold");
        doc.text(x, 6 * y + 15, "Moyen de paiement: ", "left");
        doc.setFont("Amiri-Regular", "light");
        doc.text(
          x_center,
          6 * y + 15,
          newInv?.codeFsp == CODE_PAYSMART
            ? "Carte via Paysmart"
            : newInv?.solutionName,
          "left"
        );

        doc.addImage(img2, "PNG", x_center - 15, pdfHInMM - 45, 30, 15);
        if (newInv?.codeFsp == CODE_PAYSMART) {
          nb = `NB: Ce reçu de paiement est généré automatiquement par la plateforme PAYSMART.`;
        } else {
          nb = `NB: Ce reçu de paiement est généré automatiquement par la plateforme PAYSMART. Les informations mentionnées sont renvoyées par la solution de paiement ${newInv?.solutionName} interfacée avec la plateforme PAYSMART.`;
        }
        var lines = doc.splitTextToSize(nb, pdfInMM - rMargin);
        doc.setDrawColor(107, 100, 93);
        doc.line(10, pdfHInMM - 25, pdfInMM - 10, pdfHInMM - 25); // horizontal line
        doc.setFontSize(10.5);
        doc.setTextColor(107, 100, 93);
        doc.text(lMargin + 5, pdfHInMM - 20, lines);
        doc.save(`Réçu de paiement ${newInv.invoiceRef}`);
      }
    },
    async printRechargeRecu(recharge) {
      let x = 20;
      let y = 20;
      let x_center = 105;
      let lMargin = 10; //left margin in mm
      let rMargin = 10; //right margin in mm
      let pdfInMM = 210; // width of A4 in mm
      let pdfHInMM = 300; // width of A4 in mm
      var nb;
      const doc = new jsPDF();
      let img = new Image();
      let img2 = new Image();
      img.src = require("@/assets/paysmartLogoM.png");
      img2.src = require("@/assets/header/logos/monettique.png");
      doc.addFileToVFS("Amiri-Regular-normal.ttf", font);
      doc.addFont("Amiri-Regular-normal.ttf", "Amiri-Regular", "normal");
      doc.addImage(img, "PNG", 7, 9, 30, 13);
      doc.setFontSize(10.5);
      doc.setTextColor(107, 100, 93);
      doc.setDrawColor(93, 93, 93);
      doc.setLineWidth(0.5);
      doc.line(10, 25, pdfInMM - 10, 25); // horizontal line
      doc.text(200, y, this.getPayTime(new Date()), "right");
      doc.setFontSize(24);
      doc.setTextColor(0, 0, 0);
      doc.setFont("Amiri-Regular", "bold");
      doc.text(x_center, y, `Réçu de paiement`, "center");
      doc.setFontSize(12);
      doc.setTextColor(0, 0, 0);
      doc.setFont("Amiri-Regular", "bold");
      doc.text(x, y + 15, "Référence de paiement: ", "left");
      doc.setFont("Amiri-Regular", "light");
      doc.text(x_center, y + 15, recharge.lastRechargeTransactionRef, "left");
      doc.setFont("Amiri-Regular", "bold");
      doc.text(x, 2 * y + 5, `Référence de l'abonnement:`, "left");
      doc.setFont("Amiri-Regular", "light");
      doc.text(x_center, 2 * y + 5, recharge?.rechargeRef, "left");
      doc.setFont("Amiri-Regular", "bold");
      doc.text(x, 2 * y + 15, `Facturier:`, "left");
      doc.setFont("Amiri-Regular", "light");
      doc.text(x_center, 2 * y + 15, "Tunisie Autoroutes", "left");
      doc.setFont("Amiri-Regular", "bold");
      doc.text(x, 3 * y + 5, `Montant payé:`, "left");
      doc.setFont("Amiri-Regular", "light");
      doc.text(
        x_center,
        3 * y + 5,
        this.getMontant(recharge?.paidAmount) + " TND",
        "left"
      );
      doc.setFont("Amiri-Regular", "bold");
      doc.text(x, 3 * y + 15, `Montant de la recharge:`, "left");
      doc.setFont("Amiri-Regular", "light");
      doc.text(
        x_center,
        3 * y + 15,
        this.getMontant(recharge?.lastRechargeAmount) + " TND",
        "left"
      );
      doc.setFont("Amiri-Regular", "bold");
      doc.text(x, 4 * y + 5, `Nouveau solde:`, "left");
      doc.setFont("Amiri-Regular", "light");
      doc.text(
        x_center,
        4 * y + 5,
        this.getMontant(recharge?.rechargeAmount) + " TND",
        "left"
      );
      doc.setFont("Amiri-Regular", "bold");
      doc.text(x, 4 * y + 15, `Statut:`, "left");
      doc.setFont("Amiri-Regular", "light");
      doc.text(x_center, 4 * y + 15, "Payé", "left");
      doc.setFont("Amiri-Regular", "bold");
      doc.text(x, 5 * y + 5, "Date et heure de paiement: ", "left");
      doc.setFont("Amiri-Regular", "light");
      doc.text(
        x_center,
        5 * y + 5,
        this.getPayTime(recharge?.lastRechargeDate),
        "left"
      );
      doc.setFont("Amiri-Regular", "bold");
      doc.text(x, 5 * y + 15, "Moyen de paiement: ", "left");
      doc.setFont("Amiri-Regular", "light");
      doc.text(x_center, 5 * y + 15, recharge?.lastRechargeMethod, "left");
      doc.addImage(img2, "PNG", x_center - 15, pdfHInMM - 45, 30, 15);
      nb = `NB: Ce reçu de paiement est généré automatiquement par la plateforme PAYSMART. Les informations mentionnées sont renvoyées par la plateforme PAYSMART.`;
      var lines = doc.splitTextToSize(nb, pdfInMM - rMargin);
      doc.setDrawColor(107, 100, 93);
      doc.line(10, pdfHInMM - 25, pdfInMM - 10, pdfHInMM - 25); // horizontal line
      doc.setFontSize(10.5);
      doc.setTextColor(107, 100, 93);
      doc.text(lMargin + 5, pdfHInMM - 20, lines);
      doc.save(`Réçu_paiement_STA_ ${recharge?.lastRechargeTransactionRef}`);
    },
    async printServiceRecu(newService) {
      console.log("service", newService);
  
      const edp = await this.addToPrint(
        newService?.paymentsList[0]?.paymentMean
      );
      console.log("service1", edp?.label);
      let x = 20;
      let y = 20;
      let x_center = 105;
      let lMargin = 10; //left margin in mm
      let rMargin = 10; //right margin in mm
      let pdfInMM = 210; // width of A4 in mm
      let pdfHInMM = 300; // width of A4 in mm
      var nb;
      const doc = new jsPDF();
      let img = new Image();
      let img2 = new Image();
      img.src = require("@/assets/paysmartLogoM.png");
      img2.src = require("@/assets/monetique.png");
      doc.addFileToVFS("Amiri-Regular-normal.ttf", font);
      doc.addFont("Amiri-Regular-normal.ttf", "Amiri-Regular", "normal");
      doc.addImage(img, "PNG", 7, 9, 30, 13);
      doc.setFontSize(10.5);
      doc.setTextColor(107, 100, 93);
      doc.setDrawColor(93, 93, 93);
      doc.setLineWidth(0.5);
      doc.line(10, 25, pdfInMM - 10, 25); // horizontal line
      doc.text(200, y, this.getPayTime(new Date()), "right");
      doc.setFontSize(24);
      doc.setTextColor(0, 0, 0);
      doc.setFont("Amiri-Regular", "bold");
      doc.text(x_center, y, `Réçu de paiement`, "center");
      doc.setFontSize(12);
      doc.setTextColor(0, 0, 0);
      doc.setFont("Amiri-Regular", "bold");
      doc.text(x, y + 15, "Référence de paiement: ", "left");
      doc.setFont("Amiri-Regular", "light");
      doc.text(
        x_center,
        y + 15,
        newService?.paymentsList[0]?.transactionReference,
        "left"
      );
      doc.setFont("Amiri-Regular", "bold");
      doc.text(x, 2 * y + 5, `Service:`, "left");
      doc.setFont("Amiri-Regular", "bold");
      // doc.text(x_center, 2 * y + 5, newService?.billerRs, "left");
      doc.text(x_center, 2 * y + 5, "Inscription Scolaire", "left");

      doc.setFont("Amiri-Regular", "bold");

      doc.text(x, 2 * y + 15, `Nom et Prénom:`, "left");
      doc.setFont("Amiri-Regular", "normal");

      doc.text(x_center, 2 * y + 15, newService?.clientName, "left");
      doc.setFont("Amiri-Regular", "bold");
      doc.text(x, 3 * y + 5, `Référence de l'inscription:`, "left");
      doc.setFont("Amiri-Regular", "light");
      doc.text(x_center, 3 * y + 5, newService?.objectRef, "left");
      doc.setFont("Amiri-Regular", "bold");
      doc.text(x, 3 * y + 15, `Année Scolaire`, "left");
      doc.setFont("Amiri-Regular", "light");
      doc.text(x_center, 3 * y + 15,newService?.objectDate ? newService?.objectDate : "2023/2024", "left");
      doc.setFont("Amiri-Regular", "bold");
      doc.text(x, 4 * y + 5, `Montant:`, "left");
      doc.setFont("Amiri-Regular", "light");
      doc.text(
        x_center,
        4 * y + 5,
        this.getMontant(newService?.objectOriginalAmount) + " TND",

        "left"
      );
      doc.setFont("Amiri-Regular", "bold");
      doc.text(x, 4 * y + 15, `Statut:`, "left");
      doc.setFont("Amiri-Regular", "light");
      doc.text(
        x_center,
        4 * y + 15,
        "Payé",

        "left"
      );
      doc.setFont("Amiri-Regular", "bold");
      doc.text(x, 5 * y + 5, `Date et heure de paiement:`, "left");
      doc.setFont("Amiri-Regular", "light");
      doc.text(
        x_center,
        5 * y + 5,
        this.getPayTime(newService?.paymentsList[0]?.paymentDate),
        "left"
      );
      doc.setFont("Amiri-Regular", "bold");
      doc.text(x, 5 * y + 15, `Moyen de paiement:`, "left");
      doc.setFont("Amiri-Regular", "light");
      doc.text(
        x_center,
        5 * y + 15,
        newService?.paymentsList[0]?.paymentMean.split("-")[0] == CODE_PAYSMART
          ? "Carte via Paysmart"
          : edp?.label,
        "left"
      );

      doc.addImage(img2, "PNG", x_center - 15, pdfHInMM - 45, 30, 15);
      if (
        newService?.paymentsList[0]?.paymentMean.split("-")[0] == CODE_PAYSMART
      ) {
        nb = `NB: Ce reçu de paiement est généré automatiquement par la plateforme PAYSMART.`;
      } else {
        nb = `NB: Ce reçu de paiement est généré automatiquement par la plateforme PAYSMART. Les informations mentionnées sont renvoyées par la solution de paiement ${edp?.label} interfacée avec la plateforme PAYSMART.`;
      }

      var lines = doc.splitTextToSize(nb, pdfInMM - rMargin);
      doc.setDrawColor(107, 100, 93);
      doc.line(10, pdfHInMM - 25, pdfInMM - 10, pdfHInMM - 25); // horizontal line
      doc.setFontSize(10.5);
      doc.setTextColor(107, 100, 93);
      doc.text(lMargin + 5, pdfHInMM - 20, lines);

      doc.save(`Réçu_paiement_CNTE_ ${newService?.objectRef}`);
    },
    async recuCnte(service){
      console.log('service123',service,Cnte_Recu)

const cnteReceipt= `${Cnte_Recu}?id=${service?.objectRef}&trs=${service?.paymentsList[0]?.billerAuthIds[0]}`
       window.open(cnteReceipt,"newWin", "width="+screen.availWidth+",height="+screen.availHeight);

    },
    validInput() {
      this.notValidAbnRef1 = false,
      this.errorAbnRef1 ="",
      this.notValidAbnRef2 = false,
      this.errorAbnRef2 =  ""
      if (this.isSteg) {
        this.notValidRef = /^[0-9]{9}$/.test(this.invoiceRef);
        this.errorRef = this.$t("abonnementInvalide");
      } else if (this.isSonede) {
        this.notValidRef = /^[0-9a-zA-Z]{6,32}$$/.test(this.invoiceRef);
        this.errorRef = this.$t("RéférenceInvalide");
      } else if (this.isCnte) {
        this.notValidRef = /^[A-Za-z0-9/,-]{6,32}$$/.test(this.invoiceRef);
        this.errorRef = this.$t("idUiqueInvalide");
//       } else if (this.isTopnet )  {
//         console.log('this.search2',this.search2)

//         if (this.search2 === "clientCin") {
//           this.notValidAbnRef2 =
//           this.search2 === "clientCin"
//             ? /^[0-9]{8}$$/.test(this.clientCin) :''
//             this.errorAbnRef2 = 'invalid clientCin';
//             console.log('this.search22222',this.errorAbnRef2)
//   console.log('this.search22222212',this.notValidAbnRef2)
// } else if (this.search2 === "clientPassport") {
//   this.notValidAbnRef2 =
//           this.search2 === "clientPassport"
//             ? /^[A-Za-z0-9]{1,8}$/.test(this.clientCin) :''
//             this.errorAbnRef2 = 'invalid passport';



// } else if (this.search2 === "clientRs") {


//   this.notValidAbnRef2 =
//           this.search2 === "clientRs"
//             ? /^[A-Za-z0-9/,-]{1,10}$/.test(this.clientCin) :''
//             this.errorAbnRef2 = 'invalid clientREs'; 

// } 
//       // } else if(this.isTopnet && this.search4){
//         else  if (this.search4 === "contractNumber") {
//           this.notValidAbnRef1 =
//           this.search4 === "contractNumber"
//             ? /^[0-9]{8}$$/.test(this.invoiceRef1) :''
//             this.errorAbnRef1 = 'invalid contractNumber';
//             console.log('this.search444',this.errorAbnRef1)
//   console.log('this.search444',this.notValidAbnRef1)
//   // Additional validations for other cases if needed
// }
//       }
 } else if (this.isTopnet) {
  console.log('this.search2', this.search2);

switch (this.search2) {
  case "clientCin":
  this.notValidAbnRef2 =
         this.search2 === "clientCin"
          ? /^[0-9]{8}$$/.test(this.clientCin) :''
            this.errorAbnRef2 = this.$t("CinInvalide");
            console.log('this.search22222',this.errorAbnRef2)
  console.log('this.search22222212',this.notValidAbnRef2)  
    break;
  case "clientPassport":
    this.notValidAbnRef2 =
          this.search2 === "clientPassport"
            ? /^[A-Za-z0-9]{1,8}$/.test(this.clientCin) :''
            this.errorAbnRef2 = this.$t("PassportInvalide");   
             break;
  case "clientCs":
      this.notValidAbnRef2 =
          this.search2 === "clientCs"
            ? /^[A-Za-z0-9/,-]{1,10}$/.test(this.clientCin) :''
            this.errorAbnRef2 = this.$t("resInvalide"); 
  break;
  case "clientMf":
      this.notValidAbnRef2 =
          this.search2 === "clientMf"
            ? /^[A-Za-z0-9/,-]{1,16}$/.test(this.clientCin) :''
            this.errorAbnRef2 = this.$t("MfInvalide");
  break;
}

console.log('this.search4', this.search4);

switch (this.search4) {
  case "contractNumber":
  this.notValidAbnRef1 =
          this.search4 === "contractNumber"
            ? /^[0-9]{8}$$/.test(this.invoiceRef1) :''
            this.errorAbnRef1 = this.$t("PhoneInvalide");
            console.log('this.search444',this.errorAbnRef1)
  console.log('this.search444',this.notValidAbnRef1)
    break;
    case "invoiceRef1":
  this.notValidAbnRef1 =
          this.search4 === "invoiceRef1"
            ? /^[A-Za-z0-9]{1,16}$/.test(this.invoiceRef1) :''
            this.errorAbnRef1 = this.$t("RéférenceInvalide");
            console.log('this.search444',this.errorAbnRef1)
  console.log('this.search444',this.notValidAbnRef1)
    break;
    case "clientCode":
  this.notValidAbnRef1 =
          this.search4 === "clientCode"
            ?/^[PE]-\d{4}-\d+$/.test(this.invoiceRef1) :''
            this.errorAbnRef1 =  this.$t("CodeInvalide");
            console.log('this.search444',this.errorAbnRef1)
  console.log('this.search444',this.notValidAbnRef1)
    break;
}

 }
      else {
        this.notValidAbnRef1 =
          this.search2 === "clientCin"
            ? /^[0-9]{8}$$/.test(this.abnRef1)
            : /^[A-Za-z0-9/,-]{8,16}$$/.test(this.abnRef1);
        this.notValidAbnRef2 =
          this.search3 === "clientPhone"
            ? /^[0-9]{8}$$/.test(this.abnRef2)
            : /^[0-9]{12}$$/.test(this.abnRef2);
        // this.errorAbnRef1 = this.$t("RéférenceInvalide");
        this.errorAbnRef2 = this.$t("RéférenceInvalide");
        console.log('this.search22222',this.errorAbnRef2)
  console.log('this.search22222212',this.notValidAbnRef2)

      }
    },
    validInputCnte() {

        this.notValidRefCnte = /^[A-Za-z0-9/,-]{6,32}$$/.test(this.invoiceCnte);
        this.errorRefCnte = this.$t("idUiqueInvalide");


},
    showNowBurger() {
      this.showBurger = !this.showBurger;
    },
    changeToSteg() {
      this.isSteg = true;
      this.isSonede = false;
    },
    changeToSonede() {
      this.isSteg = false;
      this.isSonede = true;
    },
    getSelectType(e) {
      var val = e.target.value;
      this.abnRef1 = val;
      this.abnRef1 = "";
    },
    getSelectType2(e) {
      var val = e.target.value;
      this.abnRef2 = val;
      this.abnRef2 = "";
    },
    getSelectTypeTopnet(e) {
      var val = e.target.value;
      this.invoiceRef1 = val;
      this.invoiceRef1 = "";
    },
    getSelectType2Topnet(e) {
      var val = e.target.value;
      this.clientCin = val;
      this.clientCin = "";
    },
    setLocale(locale) {
      this.$i18n.locale = locale;
      localStorage.setItem("lang", locale);
      var body = document.querySelector("body");
      if (locale == "ar" || locale == "tn") {
        body.className = "rtl";
      } else {
        body.className = "";
      }
    },
    showMenu() {
      this.showMobileMenu = !this.showMobileMenu;
    },
    closeSearch() {
      this.searchDealg = false;
      this.addAccount = false;
      this.addAccountTopnet =false
      this.isSonede = true;
      this.isSteg=false;
      this.isSta=false;
      this.isCnte=false;
      this.isTopnet= false
    },
  async   closeSearchtopnet() {
     await this.resetCapt()
   await   this.closeSearch()
      this.invoiceRef1 = ""
      this.clientCode = ""
      this.clientCin = ""
      this.clientMF = ""
      this.search4= "contractNumber"
      this.search2 = "clientCin"
      this.$refs.recaptcha.reset();
    this.captcha=false
    },
    closeList() {
      this.dialog = false;
    },
    closeDescr() {
      this.desc = false;
      this.detailDialog = false;
      this.dialog = true;
    },
    closeDescrService() {
      this.desc = false;
      this.detailDialogService = false;
      this.dialog = true;
    },
    closePaiment() {
      this.payDialog = false;
      if (this.desc) this.detailDialog = true;
      else this.dialog = true;
    },
    onActionClicked(action, data) {
      console.log("slot actions: on-click", data.name);
    },
    deleteSuccess() {
      this.$swal({
        text: this.$t("del_body"),
        icon: "success",
        confirmButtonText: this.$t("ok"),
      });
    },
    deleteOne(id) {
      this.removeOneItem(id);
      this.deleteSuccess();
      this.invoiceData = localStorage.getItem("invoices")
        ? JSON.parse(localStorage.getItem("invoices"))
        : [];
      this.desserts = this.invoiceData;
    },
    deleteAll() {
      this.removeAll();
      this.deleteSuccess();
      this.desserts = [];
    },
    deleteOneRecharge(id) {
      console.log("itemmmmmmmmmmmm", id);
      this.removeOneRecharge(id);
      this.deleteSuccess();
      this.chargingDesserts = localStorage.getItem("recharges")
        ? JSON.parse(localStorage.getItem("recharges"))
        : [];
    },
    deleteAllRecharges() {
      this.removeAllRecharges();
      this.deleteSuccess();
      this.chargingDesserts = [];
    },
    deleteOneService(id) {
      console.log("itemmmmmmmmmmmm", id);
      this.removeOneService(id);
      this.deleteSuccess();
      this.serviceDesserts = localStorage.getItem("services")
        ? JSON.parse(localStorage.getItem("services"))
        : [];
    },
    getImage(billet) {
      if (billet == "502") return require("@/assets/images/partner/sonde.png");
      else if (billet == "701")
        return require("@/assets/images/partner/steg.png");
      else if (billet == "004")
        return require("@/assets/images/partner/CNTE.png");
      else if(billet == "600")
      return  require("@/assets/images/TOPNET.png");
      else return require("@/assets/images/partner/sta.png");
    },

    ...mapActions([
      "getFromLocalStorage",
      "checkPayment",
      "addItemsToPayment",
      "openPaymentWindow",
      "setItem",
      "setInvoices",
      "setInvoice",
      "setRecharges",
      "setRecharge",
      "setSearchBills",
      "InitSearchBills",
      "setSubCategory",
      "responseCaptcha",
      "fetchInvoices",
      "fetchRecharges",
      "refreshInvoice",
      "getFromLocalStorage",
      "removeOneItem",
      "removeOneRecharge",
      "removeOneService",
      "updateInvoices",
      "getInvoiceById",
      "removeAll",
      "removeAllRecharges",
      "resetCart",
      "printInvoice",
      "addToPrint",
      "getRecharge",
      "getRechargeAmount",
      "confirmCharging",
      "fetchRechargeByRef",
      "verifyRechargeWithoutMsg",
      "fetchServices",
      "setService",
      "confirmPaymentSchool",
      "refreshPaymentSchool",
      "refreshServicesData"
    ]),

    pagination(p) {
      this.length = p.itemsLength;
    },
    DeleteInvoices() {
      this.resetLocalStorage();
      this.resetCart();
      this.isVisible2 = false;
      this.display = false;
      this.$vm2.close("modal2");
      this.isVisible1 = false;
      this.$vm2.close("modal1");
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    dataManager(sortOrder, pagination) {
      if (this.data.length < 1) return;

      let local = this.data;

      if (sortOrder.length > 0) {
        console.log("orderBy:", sortOrder[0].sortField, sortOrder[0].direction);
        local = _.orderBy(
          local,
          sortOrder[0].sortField,
          sortOrder[0].direction
        );
      }

      pagination = this.$refs.vuetable.makePagination(
        local.length,
        this.perPage
      );
      console.log("pagination:", pagination);
      let from = pagination.from - 1;
      let to = from + this.perPage;

      return {
        pagination: pagination,
        data: _.slice(local, from, to),
      };
    },
    getName(name) {
      console.log("Bienvenue" + name);
      return this.$t("modalDetail.Bienvenue") + " " + name;
    },
    newSearch() {
      this.dialog = false;
      document.getElementById("add-new-bill").scrollIntoView();
    },
    getDate(date) {
      if (!date) return "";
      const currentDate = new Date(date);
      if (!isNaN(currentDate)) {
        return (
          this.fixDigits(currentDate.getDate()) +
          "/" +
          this.fixDigits(currentDate.getMonth() + 1) +
          "/" +
          this.fixDigits(currentDate.getFullYear())
        );
      }
    },
    getMontant(money) {
      return parseFloat(money / 1000).toFixed(3);
    },
    getServiceName(biller) {
      console.log("billlllll", biller);
      return this.$t("sta");
    },
    getNumFact(num) {
      return this.$t("modalDetail.facdetails") + " " + num;
    },
    fixDigits(digits) {
      if (parseFloat(digits) < 10) return "0" + digits;
      return digits;
    },
    onVerify(response) {
      if (response) {
        console.log(`response`, response);
        this.captcha = true;
        this.responseCaptcha(response);
        this.Captcha=response;
      }
    },
    onVerifyCnte(response) {
      if (response) {
        console.log(`response`, response);
        this.captcha3 = true;
        this.responseCaptcha(response);
        this.Captcha=response;
      }
    },
    
    onVerify2(response) {
      if (response) {
        console.log(`response`, response);
        this.captcha2 = true;
        this.responseCaptcha(response);
        this.Captcha=response;
      }
    },
    handleClose() {
      this.isVisible1 = false;
      this.$vm2.close("modal1");
      this.isVisible2 = true;
      this.display = true;
      this.$vm2.open("modal2");
    },
    // handleCloseDesc() {
    //   this.invoiceData = JSON.parse(localStorage.getItem("invoices"));
    //   this.data = this.invoiceData;
    //   this.isVisible4 = false;
    //   this.$vm2.close("modalDesc");
    //   this.isVisible2 = false;
    //   this.display = false;
    //   this.$vm2.close("modal2");
    //   this.isVisible1 = true;
    //   this.$vm2.open("modal1");
    // },
    handleClosePay() {
      this.invoiceData = localStorage.getItem("invoices")
        ? JSON.parse(localStorage.getItem("invoices"))
        : [];
      this.data = this.invoiceData;
      this.isVisible3 = false;
      this.$vm2.close("modalPaiement");
      this.display = false;
      this.isVisible2 = false;
      this.$vm2.close("modal2");
      this.isVisible1 = true;
      this.$vm2.open("modal1");
    },
    open() {
      if (this.isSta) {
        this.chargingDesserts = localStorage.getItem("recharges")
          ? JSON.parse(localStorage.getItem("recharges"))
          : [];
        this.addAccount = false;
      }  else if  (this.isCnte){
    const     dataService = localStorage.getItem("services")
            ? JSON.parse(localStorage.getItem("services"))
            : [];
          this.serviceDesserts = dataService;
      }
      
      else  {
        this.searchDealg = false;
        this.addAccountTopnet =false
        this.invoiceData = localStorage.getItem("invoices")
          ? JSON.parse(localStorage.getItem("invoices"))
          : [];
        this.desserts = this.invoiceData;
      }
      this.dialog = true;
    },
    referechdata() {
      this.invoiceData = localStorage.getItem("invoices")
        ? JSON.parse(localStorage.getItem("invoices"))
        : [];
      this.desserts = this.invoiceData;
      this.$swal({
        text: this.$t("del_body"),
        icon: "success",
        confirmButtonText: this.$t("ok"),
      });
    },
    openDetail(id) {
      this.invoiceData = localStorage.getItem("invoices")
        ? JSON.parse(localStorage.getItem("invoices")).filter(
            (el) => el.id === id
          )[0]
        : {};
      console.log(this.invoiceData);
      const payment = this.invoiceData?.acceptedPaymentModes.filter(
        (el) => el.paymentMode == "W"
      )[0];
      this.qrcode = payment?.destinationAccount;
      console.log(this.invoiceData);
      this.dialog = false;
      this.detailDialog = true;
      this.setInvoice(this.invoiceData);
      console.log("mrx2",this.invoice )
    },
    openDetailService(item) {
      console.log(item);
      const payment = item?.acceptedPaymentModes.filter(
        (el) => el.paymentMode == "W"
      )[0];
      this.qrcodeService = payment?.destinationAccount;
      console.log("this.qrcode",payment?.destinationAccount)
      this.dialog = false;
      this.detailDialogService = true;
      this.setService(item);
    },
    openPaiment(paymentId, invoiceRef) {
      console.log("test");
      const isShow = true;
      console.log("isShow :>> ", isShow);
      console.log("invoiceRef :>> ", invoiceRef);
      let List = [];
      List.push({ id: paymentId });
      localStorage.setItem("invoiceId", JSON.stringify(List));
      if (isShow) {
        this.getInvoiceById(paymentId);
        console.log("mrx1",         this.getInvoiceById(paymentId)
);
        const payMethod = JSON.parse(localStorage.getItem("invoices")).filter(
          (el) => el.id === paymentId
        )[0].acceptedPaymentModes;
        console.log(`paymentMethod`, payMethod);
        this.isCard =
          payMethod?.filter((el) => el.paymentMode === "C").length > 0;
        this.dialog = false;
        this.payDialog = true;
      }
    },
    openPaimentDesc(data) {
      const paymentId = data.id
      const invoiceRef = data.invoiceRef
      console.log("test");
      const isShow = true;
      console.log("isShow :>> ", isShow);
      console.log("invoiceRef :>> ", invoiceRef);
      let List = [];
      List.push({ id: paymentId });
      localStorage.setItem("invoiceId", JSON.stringify(List));
      if (isShow) {
        this.getInvoiceById(paymentId);
        console.log("èèèèèèèèèè", );
        const payMethod = JSON.parse(localStorage.getItem("invoices")).filter(
          (el) => el.id === paymentId
        )[0].acceptedPaymentModes;
        console.log(`paymentMethod`, payMethod);
        this.isCard =
          payMethod?.filter((el) => el.paymentMode === "C").length > 0;
        this.detailDialog = false;
        this.openWindow("C", this.Click_To_Pay, data);
      }
    },
    async openPaimentService() {
      console.log("this.service", this.service);
      this.detailDialogService = false;
      this.dialog = true;
      this.load = true;

      await this.confirmPaymentSchool({
        method: "C",
        paymentCanal: "C01",
        paymentMean: this.Click_To_Pay,
        objectRef: this.service?.objectRef,
        biller: this.service?.biller,
        id: this.service?.id,
      })
        .then((res) => {
          console.log(res);
          this.load = false;
          const dataService = localStorage.getItem("services")
            ? JSON.parse(localStorage.getItem("services"))
            : [];
          this.serviceDesserts = dataService;
          this.detailDialogService =true
        })
        .catch((err) => {
          console.log(err);
          return Swal.fire({
            icon: "error",
            text: this.$t("modalDetail.errclickToPay"),
            confirmButtonColor: "#1db7c2",
            confirmButtonText: this.$t("modalDetail.Fermer"),
          });
        });
    },
    async openWindow(method, paymentMean,invoiceData) {
      this.load = true;
      console.log("ssssssss-------------");
      console.log(method);
      console.log("paymentMean-------------");
      console.log(paymentMean);
      console.log(localStorage.getItem("invoiceId"));
      localStorage.setItem("start", false);
      const id = JSON.parse(localStorage.getItem("invoiceId"));
      console.log("mrx ", id)
      const dataTOreturn = await this.addItemsToPayment({
        invoiceId: id[0]?.id,
        paymentMean: paymentMean,
        objectRef :invoiceData.invoiceRef,
        biller: invoiceData.biller

      });
      console.log("***********hello world****11", dataTOreturn);
      console.log("***********hello world****", id[0]?.id);

      if (dataTOreturn) {
        const datatest = await this.checkPayment(method);
        console.log("datatestdatatest123456", datatest);
        console.log("datatestdatatest123456", datatest.url);
        if (datatest?.url && datatest.done) {
          datatest.id = invoiceData.id
          this.openPaymentWindow(datatest);
          this.payDialog = false;
          this.dialog = true;
          this.load = false;
          const timer = setInterval(async function () {
            if (localStorage.getItem("start")) {
              this.invoiceData = localStorage.getItem("invoices")
                ? JSON.parse(localStorage.getItem("invoices"))
                : [];
              this.desserts = this.invoiceData;
              this.payDialog = false;
              this.dialog = false;
              this.load = false;
              clearInterval(timer);
            }
          }, 500);
        } else {
          this.load = false;

          return Swal.fire({
            icon: "error",
            text: this.$t("modalDetail.errclickToPay"),
            confirmButtonColor: "#1db7c2",
            confirmButtonText: this.$t("modalDetail.Fermer"),
          });
        }
      } else if (!dataTOreturn) {
        this.load = false;
        
        await this.refreshInv(invoiceData);
        console.log("hello invoice paid",this.invoiceData)
        Swal.fire({
                  icon: "success",
                  text: "Facture Deja Payé ",
                  confirmButtonColor: "#1db7c2",
                  confirmButtonText: "Fermer",
                });
      } else {
        this.load = false;

        return Swal.fire({
          icon: "error",
          text: this.$t("modalDetail.errclickToPay"),
          confirmButtonColor: "#1db7c2",
          confirmButtonText: this.$t("modalDetail.Fermer"),
        });
      }
    },
    handleClose2() {
      this.isVisible2 = false;
      this.display = false;
      this.$vm2.close("modal2");
      if (this.new) {
        this.invoiceData = localStorage.getItem("invoices")
          ? JSON.parse(localStorage.getItem("invoices"))
          : [];
        this.data = this.invoiceData;
        this.display = false;
        this.$vm2.open("modal1");
        this.new = false;
      }
    },

    open2(type) {
      (this.invoiceRef = ""), (this.search = "invoiceRef");
      this.display = true;
      if (type == "steg") {
        this.defaultSelected = this.options[0];
        this.isSteg = true;
        this.isSonede = false;
        this.isCnte = false;
      } else if (type == "sonde") {
        this.defaultSelected = this.options[1];
        this.isSteg = false;
        this.isSonede = true;
        this.isCnte = false;
        this.search = "contractNumber";
      } else if (type == "cnte") {
        console.log
        this.defaultSelected = this.options[3];
        this.isSteg = false;
        this.isSonede = false;
        this.isCnte = true;
        this.search = "objectReference";
      }
      this.resetCapt();
    },
    resetCapt() {
      try {
        this.$refs.recaptcha.reset();
        this.captcha = false;
        this.$refs.recaptcha2.reset();
        this.$refs.recaptcha3.reset();

        this.captcha2 = false;
        this.captcha3 = false;
      } catch (e) {
        console.log(this.defaultSelected);
      }
    },
    openTopnet(){
      this.resetCapt()
      this.isParticular=true,
      this.isCompany =false,
      this.invoiceRef1='',
      this.clientCin =''
this.search4 = "contractNumber",
this.search2="clientCin"

this.clientCode = ""
    this.clientCin = ""
    this.clientMF = ""
    },
    openNew() {
      this.new = true;
      this.display = true;
      this.invoiceRef = "";
      this.defaultSelected = this.options[1];
      this.isVisible1 = false;
      this.$vm2.close("modal1");
      this.isVisible2 = true;
      this.$vm2.open("modal2");
      this.$refs.recaptcha.reset();
      this.captcha = false;
    },

    getColorEtat(etat) {
      if (etat == "PAID" || etat == "HPAID")
        return "background: #08b957 !important;color:#fff !important";
      else return "background:#ffae63 !important;color:#fff !important";
    },
    getColorEtatBill(etat) {
      if (etat?.toLowerCase() == "paid" || etat?.toLowerCase() == "HPAID")
        return "paid";
      else return   "expired-btn";

    },
    getColorEtatRecharge(etat) {
      if (etat == "Active")
      return "paid";
      else if (etat == "supended")
        return  "expired";
     else       return   "expired-btn";


    },
    getColorEtatService(etat) {
      if (etat == "Active")
        return "background: #08b957 !important;color:#fff !important";
      else if (etat == "supended")
        return "background:#ea201d !important;color:#fff !important";
      else return "background:#ffae63 !important;color:#fff !important";
    },
    getString(etat) {
      if (etat == "invoiceRef") return this.$t("modalConsultation.referenceF");
      else if (etat == "clientCode")
        return this.$t("modalConsultation.clientcode");
      else return this.$t("modalConsultation.contract");
    },
    getNameEtat(etat) {
      if (etat == "PAID" || etat == "HPAID") return this.$t("payee");
      else return this.$t("impayee");
    },
    getMinistre(etat){
      if(etat)
      return this.$t("modalDetail.ministre");     },
    formatStatus(status) {
      switch (status) {
        case "Active":
          return this.$t("modalDetail.alimente");
        case "Limited":
          return this.$t("modalDetail.limite");
        case "supended":
          return this.$t("modalDetail.epuise");
        default:
          return this.$t("modalDetail.limite");
      }
    },
    expiredCaptcha() {
      this.captcha = false;
      this.responseCaptcha("");
      this.Captcha="";
    },
    expiredCaptcha2() {
      this.captcha2 = false;
      this.responseCaptcha("");
      this.Captcha="";
    },
    expiredcaptcha3() {
      this.captcha3 = false;
      this.responseCaptcha("");
    },
    async goToDetail() {
      var query = "";   
      if (this.isSta) {
        console.log("123456789", this.search);
        switch (this.search2) {
          case "clientCin":
            switch (this.search3) {
              case "clientPhone":
                query = { clientCin: this.abnRef1, clientPhone: this.abnRef2 };
                break;
              case "RechargeRef":
                query = { clientCin: this.abnRef1, RechargeRef: this.abnRef2 };
                break;
              default:
                break;
            }
            break;
          case "clientMf":
            switch (this.search3) {
              case "clientPhone":
                query = { clientMf: this.abnRef1, clientPhone: this.abnRef2 };
                break;
              case "RechargeRef":
                query = { clientMf: this.abnRef1, RechargeRef: this.abnRef2 };
                break;
              default:
                break;
            }
            break;
          default:
            break;
        }
        this.load = true;
        const error = await this.fetchRecharges({
          subCategory: this.options[2].billet,
          query,
          secaptcha:this.Captcha
        });
        this.load = false;
        this.afterPayDialog = false;
        if (!error) {
          this.afterPayDialog = false;
          this.load = false;
          this.isCharging = true;
          this.isBill = false;
          this.captcha2 = false;
          this.$refs.recaptcha2.reset();
          this.captcha = false;
          this.abnRef1 = "";
          this.abnRef2 = "";
          this.closeSearch();
          this.open();

        } else {
          this.captcha2 = false;
          this.$refs.recaptcha2.reset();
          this.captcha = false;
          this.abnRef1 = "";
          this.abnRef2 = "";
          this.load = false;
          
          this.closeSearch();
          if (error.type == "error") {
            console.log(error.message);
            switch (error.message) {
              case "Not Found":
                return this.$toast.error(this.$t("notfoureer"));
              case "No invoices found":
                return this.$toast.error(this.$t("notfoureer"));
              case "Service Unavailable" || "Biller system unreachable":
                return this.$toast.error(this.$t("errinternal"));
              case "Internal Server error" || "Internal Server Error":
                return this.$toast.error(this.$t("critererr"));
              case "Bad Request" || "Invalid search parameters":
                return this.$toast.error(this.$t("critererr"));
              default:
                return this.$toast.error(error.message);
            }
          } else {
            this.abnRef1 = "";
            this.abnRef2 = "";
            this.$refs.recaptcha2.reset();
            this.captcha2 = false;
            this.$toast.info(error.message);
          }
        }
      } else if(this.isTopnet){
        let query = {}; // Initialize an empty object

      //  if(this.isParticular){


      switch (this.search4) {
       case "clientCode":
        query.clientCode = this.invoiceRef1;
       console.log('helloclientCode ',query)

       break;
       case "invoiceRef1":
       query.objectReference = this.invoiceRef1;
       console.log('helloinvoiceRef1 ',query)

       break;
       case "contractNumber":
       query.contractNumber = this.invoiceRef1;
       console.log('contractNumber ',query)

       break;
       default:
       break;
      }
       query[this.search2] = this.clientCin;


//       }
//       if(this.isCompany){


//       switch (this.search4) {
//        case "clientCode":
//        query.clientCode = this.invoiceRef1;

//        break;
//        case "invoiceRef1":
//        query.objectReference = this.invoiceRef1;

//        break;
//        case "contractNumber":
//        query.contractNumber = this.invoiceRef1;

//        break;
//        default:
//        break;
//       }
//       query.clientMf = this.clientCin;


// }
       {
        // if (this.searchbills.length < this.maxsearchnbr) {
        //   console.log('queryyyyyy123456789',query)
        //   console.log('queryyyyyy123456789',this.options[4].billet)

        //   const error = await this.fetchInvoices({
        //     subCategory: this.options[4].billet,
        //     query,
        //   });
        //   if (!error) {
        //     this.setSearchBills(Date.now());
        //     this.load = false;
        //     this.isCharging = false;
        //     this.isBill = true;
        //     this.open();
        //   } else {
        //     this.captcha = false;
        //     this.$refs.recaptcha.reset();
        //     this.captcha = false;
        //     this.invoiceRef1 = "";
        //     this.load = false;
        //     this.closeSearch();
        //     this.defaultSelected = this.options[1]; 
        //     this.isSteg = false; 
        //     this.isSonede = true; 
        //     this.isCnte = false; 
        //     this.search4 = "contractNumber"
        //     if (error.type == "error") {
        //       console.log(error.message);
        //       switch (error.message) {
        //         case "Not Found":
        //           return this.$toast.error(this.$t("notfoureer"));
        //         case "No invoices found":
        //           return this.$toast.error(this.$t("notfoureer"));
        //         case "Service Unavailable" || "Biller system unreachable":
        //           return this.$toast.error(this.$t("errinternal"));
        //         case "Internal Server Error":
        //           return this.$toast.error(this.$t("errinternal"));
        //         case "Bad Request" || "Invalid search parameters":
        //           return this.$toast.error(this.$t("critererr"));
        //         default:
        //           return this.$toast.error(error.message);
        //       }
        //     } else {
        //       this.invoiceRef1 = "";
        //       this.$refs.recaptcha.reset();
        //       this.captcha = false;
        //       this.$toast.info(error.message);
        //     }
        //   }
        // } else if (
        //   this.searchbills.length == this.maxsearchnbr &&
        //   new Date().getTime() / 60000 - this.searchbills[0] / 60000 >
        //     this.maxsearchtmp
        // ) {
          this.load = true;
         console.log("queryyTopnet",query)
          const error = await this.fetchInvoices({
            subCategory: this.options[4].billet,
            query,
            secaptcha:this.Captcha
          });
          this.load = false;

          if (!error) {

            this.isCharging = false;
            this.isBill = true;
            this.InitSearchBills();
            this.setSearchBills(Date.now());
            this.load = false;
            this.open();
            this.invoiceRef1 = "";
            this.clientCin = ""
            this.captcha2 = false;
            this.resetCapt()
          } else {
            
            this.captcha2 = false;
            this.captcha = false;
            this.invoiceRef1 = "";
            this.clientCin = ""
            this.load = false;
            this.closeSearch();

            this.defaultSelected = this.options[1]; 
            this.isSteg = false; 
            this.isSonede = true; 
            this.isCnte = false; 
            this.isTopnet=false
            this.search4 = "contractNumber"
            this.search2 = 'clientCin'
            this.resetCapt()

            if (error.type == "error") {
              switch ("err finale",error.message) {
                case "Not Found":
                  return this.$toast.error(this.$t("notfoureer"));
                case "No invoices found":
                  return this.$toast.error(this.$t("notfoureer"));
                case "Service Unavailable":
                  return this.$toast.error(this.$t("errinternal"));
                case "Internal Server Error":
                  return this.$toast.error(this.$t("errinternal"));
                case "Bad Request" || "Invalid search parameters":
                  return this.$toast.error(this.$t("critererr"));

                default:
                  return this.$toast.error(error.message);
              }
            } else {
              this.invoiceRef1 = "";
              this.clientCode = ""
            this.clientCin = ""
            this.clientMF = ""
              this.$refs.recaptcha.reset();
              this.captcha = false;
            this.resetCapt()

              this.$refs.recaptcha2.reset();
              this.captcha2 = false;
              this.$toast.info(error.message);
            }
          }
       // } 
        // else {
        //   this.load = false;
        //   this.invoiceRef1 = "";
        //   this.$refs.recaptcha.reset();
        //   this.$toast.error(this.$t("maxsearch"));
        // }
      }

      }    
      else if (this.isCnte) {
        switch (this.search) {
          case "objectReference":
            query = { objectReference: this.invoiceRef };
            break;
          default:
            break;
        }
        // if (this.searchbills.length < this.maxsearchnbr) {
        const error = await this.fetchServices({
          subCategory: this.defaultSelected.billet,
          query,
          secaptcha:this.Captcha
        });
        if (!error) {
          console.log('iscnteiscnte1')
          this.setSearchBills(Date.now());
          this.load = false;
          this.isCharging = false;
          this.isBill = false;
          this.isServices = true;
          this.serviceDesserts = this.services;
          this.open();
        } else {
          console.log('iscnteiscnte2')

          this.captcha = false;
          this.$refs.recaptcha.reset();
          this.captcha = false;
          this.invoiceRef = "";
          this.load = false;

        this.defaultSelected = this.options[1];
        this.isSteg = false;
        this.isSonede = true;
        this.isCnte = false;
        this.search = "contractNumber";

        this.closeSearch();
          if (error.type == "error") {
            console.log(error.message);
            switch (error.message) {
              case "Length Required" :
              return this.$toast.error(this.$t("isPaid")); 
              case "Not Found":
                return this.$toast.error(this.$t("notfoureer"));
              case "No invoices found":
                return this.$toast.error(this.$t("notfoureer"));
              case "Service Unavailable" || "Biller system unreachable":
                return this.$toast.error(this.$t("errinternal"));
              case "Internal Server Error":
                return this.$toast.error(this.$t("errinternal"));
              case "Bad Request" || "Invalid search parameters":
                return this.$toast.error(this.$t("critererr"));
              default:
                return this.$toast.error(error.message);
            }
          } else {
            this.defaultSelected = this.options[1];
        this.isSteg = false;
        this.isSonede = true;
        this.isCnte = false;
        this.search = "contractNumber";
            this.invoiceRef = "";
            this.$refs.recaptcha.reset();
            this.captcha = false;
            this.$toast.info(error.message);
          }
        }
        //}
        //  else if (
        //   this.searchbills.length == this.maxsearchnbr &&
        //   new Date().getTime() / 60000 - this.searchbills[0] / 60000 >
        //     this.maxsearchtmp
        // ) {
        //   const error = await this.fetchInvoices({
        //     subCategory: this.defaultSelected.billet,
        //     query,
        //   });
        //   if (!error) {
        //     this.InitSearchBills();
        //     this.setSearchBills(Date.now());
        //     this.load = false;
        //     this.open();
        //     this.invoiceRef = "";
        //     this.$refs.recaptcha.reset();
        //     this.captcha = false;
        //   } else {
        //     this.captcha = false;
        //     this.load = false;
        //     this.closeSearch();
        //     if (error.type == "error") {
        //       switch (error.message) {
        //         case "Not Found":
        //           return this.$toast.error(this.$t("notfoureer"));
        //         case "No invoices found":
        //           return this.$toast.error(this.$t("notfoureer"));
        //         case "Service Unavailable":
        //           return this.$toast.error(this.$t("errinternal"));
        //         case "Internal Server Error":
        //           return this.$toast.error(this.$t("errinternal"));
        //         case "Bad Request" || "Invalid search parameters":
        //           return this.$toast.error(this.$t("critererr"));

        //         default:
        //           return this.$toast.error(error.message);
        //       }
        //     } else {
        //       this.invoiceRef = "";
        //       this.$refs.recaptcha.reset();
        //       this.captcha = false;
        //       this.$toast.info(error.message);
        //     }
        //   }
        // } else {
        //   this.load = false;
        //   this.invoiceRef = "";
        //   this.$refs.recaptcha.reset();
        //   this.$toast.error(this.$t("maxsearch"));
        // }
      } else if (
        (this.search && this.isSteg) ||
        (this.search && this.isSonede)
      ) {
        switch (this.search) {
          case "invoiceRef":
            query = { invoiceRef: this.invoiceRef };
            break;
          case "contractNumber":
            query = { contractNumber: this.invoiceRef };
            break;
          default:
            break;
        }
        if (this.searchbills.length < this.maxsearchnbr) {
          const error = await this.fetchInvoices({
            subCategory: this.defaultSelected.billet,
            query,
            secaptcha:this.Captcha
          });
          if (!error) {
            this.setSearchBills(Date.now());
            this.load = false;
            this.isCharging = false;
            this.isBill = true;
            this.isServices=false

            this.open();
          } else {
          this.resetCapt()

            this.captcha = false;
            this.$refs.recaptcha.reset();
            this.captcha = false;
            this.invoiceRef = "";
            this.load = false;
            this.closeSearch();
            this.defaultSelected = this.options[1]; 
            this.isSteg = false; 
            this.isSonede = true; 
            this.isCnte = false; 
            this.isTopnet =false
            this.search = "contractNumber"
            if (error.type == "error") {
              console.log(error.message);
              switch (error.message) {
                case "Length Required" :
                return this.$toast.error(this.$t("isPaid")); 
                case "Not Found":
                  return this.$toast.error(this.$t("notfoureer"));
                case "No invoices found":
                  return this.$toast.error(this.$t("notfoureer"));
                case "Service Unavailable" || "Biller system unreachable":
                  return this.$toast.error(this.$t("errinternal"));
                case "Internal Server Error":
                  return this.$toast.error(this.$t("errinternal"));
                case "Bad Request" || "Invalid search parameters":
                  return this.$toast.error(this.$t("critererr"));
                default:
                  return this.$toast.error(error.message);
              }
            } else {
              this.resetCapt()

              this.invoiceRef = "";
              this.$refs.recaptcha.reset();
              this.captcha = false;
              this.$toast.info(error.message);
            }
          }
        } else if (
          this.searchbills.length == this.maxsearchnbr &&
          new Date().getTime() / 60000 - this.searchbills[0] / 60000 >
            this.maxsearchtmp
        ) {
          const error = await this.fetchInvoices({
            subCategory: this.defaultSelected.billet,
            query,
            secaptcha:this.Captcha
          });
          if (!error) {
            this.InitSearchBills();
            this.setSearchBills(Date.now());
            this.load = false;
            this.open();
            this.invoiceRef = "";
            this.$refs.recaptcha.reset();
            this.captcha = false;
            this.resetCapt()

          } else {
            this.resetCapt()

            this.captcha = false;
            this.$refs.recaptcha.reset();
            this.captcha = false;
            this.invoiceRef = "";
            this.load = false;
            this.closeSearch();

            this.defaultSelected = this.options[1]; 
            this.isSteg = false; 
            this.isSonede = true; 
            this.isCnte = false; 
            this.search = "contractNumber"
            if (error.type == "error") {
              switch (error.message) {
                case "Length Required" :
                return this.$toast.error(this.$t("isPaid")); 
                case "Not Found":
                  return this.$toast.error(this.$t("notfoureer"));
                case "No invoices found":
                  return this.$toast.error(this.$t("notfoureer"));
                case "Service Unavailable":
                  return this.$toast.error(this.$t("errinternal"));
                case "Internal Server Error":
                  return this.$toast.error(this.$t("errinternal"));
                case "Bad Request" || "Invalid search parameters":
                  return this.$toast.error(this.$t("critererr"));

                default:
                  return this.$toast.error(error.message);
              }
            } else {
              this.invoiceRef = "";
              this.$refs.recaptcha.reset();
              this.captcha = false;
              this.resetCapt()

              this.$toast.info(error.message);
            }
          }
        } else {
          this.load = false;
          this.invoiceRef = "";
          this.$refs.recaptcha.reset();
          this.$toast.error(this.$t("maxsearch"));
        }
      }
      this.$refs.recaptcha.reset();
      this.captcha = false;
      this.resetCapt()
      this.defaultSelected = this.options[1]; 
      this.search = "contractNumber"
      this.isSteg = false; 
      this.isSonede = true; 
      this.isCnte = false;
      this.isTopnet = false;
      this.isSta = false
      this.invoiceRef = "";
      this.abnRef1 = "";
      this.abnRef2 = "";
    },
      async goToDetailCnte() {
      var query = "";

            query = { objectReference: this.invoiceCnte };
 
        const error = await this.fetchServices({
          subCategory: this.options[3].billet,
          query,
          secaptcha:this.Captcha
        });
        if (!error) {
          console.log('iscnteiscnte1')
          this.setSearchBills(Date.now());
          this.load = false;
          this.isCharging = false;
          this.isBill = false;
          this.isServices = true;
          this.serviceDesserts = this.services;
          this.invoiceCnte = "";
          this.$refs.recaptcha3.reset();
        
      
      this.captcha3 = false;
      this.resetCapt()
          this.open();
        } else {
          console.log('iscnteiscnte2')

          this.captcha3 = false;
          this.$refs.recaptcha3.reset();
          this.invoiceCnte = "";
          this.load = false;
      
      this.resetCapt()
       

        this.closeSearch();
          if (error.type == "error") {
            console.log(error.message);
            switch (error.message) {
              case "Length Required" :
              return this.$toast.error(this.$t("isPaid")); 
              case "Not Found":
                return this.$toast.error(this.$t("notfoureer"));
              case "No invoices found":
                return this.$toast.error(this.$t("notfoureer"));
              case "Service Unavailable" || "Biller system unreachable":
                return this.$toast.error(this.$t("errinternal"));
              case "Internal Server Error":
                return this.$toast.error(this.$t("errinternal"));
              case "Bad Request" || "Invalid search parameters":
                return this.$toast.error(this.$t("critererr"));
              default:
                return this.$toast.error(error.message);
            }
          } else {
  
            this.invoiceCnte = "";
            this.$refs.recaptcha.reset();
            this.$refs.recaptcha3.reset();

            this.captcha3 = false;
            this.$toast.info(error.message);
            this.resetCapt()

          }
        }
     
          this.load = false;
          this.invoiceCnte = "";
          this.$refs.recaptcha3.reset();
        
      
      this.captcha3 = false;
      this.resetCapt()
      console.log('hello faten',this.invoiceCnte,this.captcha3)
    },
    async refreshService(service){
 
      const error = await this.refreshServicesData(service);
      if (!error) {
        this.serviceDesserts = localStorage.getItem("services")
          ? JSON.parse(localStorage.getItem("services"))
          : [];
      }
    },
    async refreshInv(refreshInv) {
      const error = await this.refreshInvoice(refreshInv);
      if (!error) {
        this.invoiceData =
          JSON.parse(localStorage.getItem("invoices")).filter(
            (el) => el.id === refreshInv.id
          )[0] ?? {};
        this.desserts = localStorage.getItem("invoices")
          ? JSON.parse(localStorage.getItem("invoices"))
          : [];
      }
    },
    async printInv(id) {
      const error = await this.printInvoice(id);
      if (!error) {
        this.invoiceData = JSON.parse(localStorage.getItem("invoices")).filter(
          (el) => el.id === id
        )[0];
        this.desserts = localStorage.getItem("invoices")
          ? JSON.parse(localStorage.getItem("invoices"))
          : [];
      }
    },
    goToPro() {
      window.open(this.loginUrl + this.$i18n.locale + "/login");
    },
    createAccount() {
      window.open(this.loginUrl + this.$i18n.locale + "/register");
    },
    login() {
      window.open(this.loginUrl + this.$i18n.locale + "/login");
    },
    getTotalPrix() {
      try {
        const invoiceData = localStorage.getItem("invoices")
          ? JSON.parse(localStorage.getItem("invoices"))
          : [];
        var sum = 0;
        for (var i = 0; i < invoiceData.length; i++) {
          if (invoiceData[i].paymentStatus.toLowerCase() !== "paid") {
            sum += Number(invoiceData[i].invoiceAmount);
          }
        }
        return parseFloat(sum / 1000).toFixed(3);
      } catch (e) {
        return 0;
      }
    },
    getTotal() {
      try {
        // const invoiceData = JSON.parse(localStorage.getItem("invoices"));
        // const rechargeData = JSON.parse(localStorage.getItem("recharges"));
        // console.log('invoiceData', invoiceData, 'rechargeData', rechargeData)
        return (
          this.chargingDesserts?.length +
          this.desserts?.length +
          this.serviceDesserts?.length
        );
      } catch (e) {
        return 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped media="print">
.section1-btns{
  align-items: center;
}
input[type=number].input-number::-webkit-inner-spin-button,
input[type=number].input-number::-webkit-outer-spin-button {
 opacity: 1 !important;
 margin: 0;
 width: 12px;
 height: 27px;
}
.section1-btns-part1 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 26px;
}
  .detail-modal{
    width: auto !important;
    padding: 0px 7px  ;

  }
  .detail-modal-all{
    width: auto !important;
    padding: 0px 7px  ;
  }
.inputStyle {
  padding-left: 8px;
  border: 1px solid #cdcdcd;
  border-radius: 8px;
  background: #ffffff;
  align-items: center;
  height: 40px !important;
}
.EspaceButton {
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;
  width: 149px;
  height: 40px;
  background: #fff;
  border-radius: 12px;
  flex: none;
  order: 2;
  flex-grow: 0;
  color: #1db7c2;
}

.divp {
  width: 30%;
  color: white;
  border-radius: 12px;
  font-size: 133%;
  font-weight: bold;
  background: #1999c2;
  padding: 1%;
}

.DivImg {
  display: flex;
  align-items: center;
  margin: -2%;
  z-index: 50;
  margin-top: -3%;
}
.elemnt {
  display: flex;
}
.imgDivResp {
  display: flex;
  margin-left: -10%;
  z-index: 23;
  width: 75%;
}

#one {
  margin-right: 20px;
  float: right;
  width: 30%;
}

#two {
  min-height: 170px;
}

.containerDesc {
  float: left;
}

.containerImg {
  float: left;
}

.body {
  display: flex;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
}

.imgLogoT {
  display: flex;
  align-items: center;
}

.LogoPaysmart {
  width: 40%;
}

.logoT {
  width: 47px;
  margin-right: 1rem;
  height: 31px;
}

.logo1 {
  width: 29px;
  height: 47px;
}

.nav-content {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
  align-items: center;
  margin: -2rem 0;
}

.nav-items {
  display: flex;
  font-size: 85%;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  color: white;
  font-size: 98%;
  width: 100%;

  li {
    padding: 0 10px;

    a {
      color: white;
      text-decoration: none;
    }
  }
}

i {
  display: none;
}
.republique {
  font-size: 13px;
  letter-spacing: 1px;
  color: #ffffff;
  font-family: "Helvetica";
  text-align: right;
}
.btn-outline-white {
  color: #fff;
  border-color: #fff;
  padding: 5px 10px;
  border-radius: 15px !important;
  &:focus,
  &:active {
    color: #fff;
    border-color: #fff;
  }
  &:hover {
    color: #337fa8;
    background-color: #fff;
    border-color: #fff;
  }
}

.my-space:hover {
  img {
    filter: opacity(0.8) drop-shadow(0 0 0 #337fa8);
  }
}
.link-active {
  position: relative;
}
.link-active:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  bottom: -10px;
  left: 5px;
  background-color: #07468a;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.link-active:hover:before,
.link-active:active:before,
.link-active:visited {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}
.type-active {
  border: 1px solid #337fa8 !important;
}
.isactive{
  border: 1px solid #337fa8 !important;
  color:#337fa8;
  font-weight: bold;

}
.section1-header {
  font-size: 68px;
  letter-spacing: -1px;
  color: #337fa8;
  font-weight: 900;
  font-family: "Source Sans Pro";
}
.section1-p {
  font-size: 20px;
  letter-spacing: 0px;
  line-height: 24px;
  color: #5f5f5f;
  font-weight: 400;
  font-family: "Source Sans Pro";
  width: 42%;
}

.burger {
  display: none;
}
#add-new-bill {
 // position: absolute;
 // transform: translate(-50%, -50%);
 // top: 45%;
 // left: 50%;
  margin : 0 auto;
  width: 1000px;
  height: 338px;
  padding: 25px;
  border-radius: 8px;
  filter: drop-shadow(0px 8px 13.5px rgba(166, 166, 166, 0.19));
  background-color: #ffffff;
  border: 1px solid #eaeaea;
}
.add-new-cnte {
 position: absolute;
 transform: translate(-50%, -50%);
 top: 22%;
 left: 49%;
  margin : 0 auto;
  width: 1000px;
  height: 338px;
  padding: 25px;
  border-radius: 8px;
  filter: drop-shadow(0px 8px 13.5px rgba(166, 166, 166, 0.19));
}
.add-new-cnte-ar {
 position: absolute;
 transform: translate(-50%, -50%);
 top: 22%;
 left: 28% ;
  margin : 0 auto;
  width: 1000px;
  height: 338px;
  padding: 25px;
  border-radius: 8px;
  filter: drop-shadow(0px 8px 13.5px rgba(166, 166, 166, 0.19));
}


.disable {
  background: #509dc7;
  opacity: 0.5;
  cursor: not-allowed;
}

.wide-width {
  width: calc(100% - 10px) !important;
}

@media screen and (max-width: 1300px) {
  .link-active,
  .btn-outline-white {
    font-size: 12px !important;
  }
  .republique {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 1200px) {
  .aux-bar {
    display: none;
  }
  .burger {
    display: block;
    top: 90px !important;
    z-index: 5 !important;
    cursor: pointer;
  }
  .show-burger {
    display: flex !important;
    position: fixed !important;
    left: 0px !important;
    right: 0px !important;
    left: 0px !important;
    top: 0px !important;
    height: 100vh !important;
    background-color: #337fa8 !important;
    color: #fff !important;
    z-index: 5 !important;
    font-size: 14px;
    letter-spacing: 1px;
    color: #ffffff;
    font-weight: 600;
    font-family: "Source Sans Pro";
    gap: 25px;
    img {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
  .btn-send {
    flex-direction: column;
  }
}
@media screen and (max-width: 1000px) {

  .detail-modal{
    width: auto !important;
    height: 50px !important;
    padding: 0px 7px  ;

  }
  .detail-modal-all{
    height: 50px !important;

    width: auto !important;
    padding: 0px 7px  ;
  }
  .wide-width {
    width: 100% !important;
  }
  .small-captcha {
    transform: scale(0.6);
  }
  .find-bills-fields {
    overflow: hidden !important;
    overflow-x: auto !important;
  }
  #add-new-bill {
    max-width: 900px !important;
    position: inherit !important;
    height: inherit !important;
    margin: 25px auto 0 auto !important;
    top: inherit !important;
    left: inherit !important;
    transform: inherit !important;
  }
  .pay-header {
    font-size: 25px !important;
  }
  .pay-p {
    font-size: 14px !important;
  }
  #add-new-bill {
    max-width: 740px !important;
  }

  .section1-header {
    font-size: 28px;
    width: 80%;
    padding-top: 36px;
  }
  .section1-p {
    font-size: 14px;
    width: 80%;
  }
  .aux-bar {
    display: none;
  }
  .burger {
    display: block;
  }
  .show-burger {
    display: flex !important;
    position: fixed !important;
    left: 0px !important;
    right: 0px !important;
    left: 0px !important;
    top: 0px !important;
    height: 100vh !important;
    background-color: #337fa8 !important;
    color: #fff !important;
    z-index: 5 !important;
  }
}
@media screen and (max-width: 800px) {
  // .section1-btns {
  //   flex-direction: column !important;
  //   align-items: center !important;
  // }
  .pay-pad {
    padding-top: 40px !important;
  }
  .pay-header {
    font-size: 24px !important;
  }
  .pay-p {
    font-size: 14px !important;
  }
  .find-bills-button {
    align-items: center !important;
  }
  #add-new-bill {
    max-width: 600px !important;
    position: inherit !important;
    height: inherit !important;
    margin: -15px auto 0 auto !important;
    top: inherit !important;
    left: inherit !important;
    transform: inherit !important;
  }
  .add-new-cnte {
    top: 28%;
    left: 50%;
  margin : 0 auto;
  height: 338px;
  padding: 25px;
  border-radius: 8px;
  filter: drop-shadow(0px 8px 13.5px rgba(166, 166, 166, 0.19));

}
.add-new-cnte-ar {
    top: 28%;
    left: 50%;
  margin : 0 auto;
  height: 338px;
  padding: 25px;
  border-radius: 8px;
  filter: drop-shadow(0px 8px 13.5px rgba(166, 166, 166, 0.19));

}
  .small-header {
    font-size: 22px !important;
  }
  .aux-sm-bar {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .add-new-cnte {
 left: 57%;}
    .section1-btns {
    flex-direction: column !important;
  }
  .section1-btns-part1 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap:1px
}  

  .responsive-div {
      flex-direction: column; 
      align-items: flex-start;
    }
  .logo-fact {
    width: 68px !important;
    height: 80px !important;
  }
  .find-bills-fields {
    flex-direction: column !important;
    gap: 15px !important;
    overflow-y: hidden !important;
  }
  .aux-sm-bar {
    display: none;
  }
  .aux-sm-bar-show {
    display: inline;
  }
  .section1-header {
    width: 100%;
  }
  .find-bills-fields {
    overflow: inherit !important;
  }
  #add-new-bill {
    width: 300px !important;
    position: inherit !important;
    height: inherit !important;
    margin: 25px auto 0 auto !important;
    top: inherit !important;
    left: inherit !important;
    transform: inherit !important;
  }
  .small-captcha {
    transform: scale(0.7);
  }
  .small-captcha2 {
    transform: scale(0.74);
    margin-top: 0px !important;
  }
  .all-rep {
    gap: 5px !important;
    margin-left: 5px !important;
    padding-left: 5px !important;

    img {
      height: 20px !important;
      width: 20px !important;
    }
    span {
      font-size: 10px !important;
    }
  }
  .all-rep-ar {
    gap: 5px !important;
    margin-right: 5px !important;
    padding-right: 5px !important;

    img {
      height: 20px !important;
      width: 20px !important;
    }
    span {
      font-size: 12px !important;
    }
  }
  .section1-header {
    font-size: 28px;
    width: 60%;
  }
  .btn-turquoise,
  .btn-turquoise-outline {
    width: 140px;
    height: 27px;
    font-size: 14px;
  }
  .detail-modal{
    width: 140px !important;
  }
  .detail-modal-all{
    width: 300px !important;
  }
  .small-header {
    font-size: 18px !important;
  }
}

.fact {
  opacity: 0.788;
  width: 170px;
  height: 54px;
  border-radius: 15px;
  filter: drop-shadow(0px 8px 13.5px rgba(14, 63, 108, 0.19));
  color: #0c7ca5;
  background: #fff;
  border: 1px solid #d8d8d8;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  span {
    font-size: 18px;
    letter-spacing: 0px;
    line-height: 32px;
    color: #0c7ca5;
    font-weight: 700;
    font-family: "Source Sans Pro";
  }
  @media screen and (max-width: 600px) {
    width: 90%;
  }
}
.navbar {
  justify-content: center !important;
  align-items: center !important;
  position: fixed !important;
  left: 0px !important;
  right: 0px !important;
  left: 0px !important;
  top: 0px !important;
  height: 89px !important;
  background-color: #337fa8 !important;
  z-index: 5 !important;
}

.navbar-nav {
  font-size: 14px;
  letter-spacing: 1px;
  color: #ffffff;
  font-weight: 600;
  font-family: "Source Sans Pro";
  gap: 10px;
  padding: 0px !important;
}

.all-rep {
  gap: 15px;
  border-left: 1.5px solid #fff;
  margin-left: 15px;
  padding-left: 15px;
}

.all-rep-ar {
  gap: 15px;
  border-right: 1.5px solid #fff;
  margin-right: 15px;
  padding-right: 15px;
}

.box-wallet {
  border-radius: 4px;
  background: rgb(255, 255, 255) !important;
  min-height: 50px;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(11% - 1rem);
  margin-bottom: 1rem;
  min-width: 70px;
  box-shadow: rgb(13 114 145 / 22%) 5px 5px 13px 0px;
}
.box-wallet-1 {

}
@media screen and (max-width: 450px) {
  .paysmart-logo,
  .paysmart-mongnetique {
    width: 90px;
    // height:45px
  }
}
.notif-dir {
  direction: ltr !important;
}
@media print {
  button {
    display: none;
  }
}
.btn-panier-outline {
  color: #10a3af;
  background-color: #fff;
  border: 2px solid hsla(185deg, 73%, 51%, 0.29);
  border-radius: 0px !important;
  border-top-right-radius: 15px !important;
  border-top-left-radius: 15px !important;
  font-size: 13px;
  font-weight: 700;
}

.btn-panier {
  color: #10a3af;
  background-color: hsla(185deg, 73%, 51%, 0.29);
  border-radius: 0px !important;
  border-top-right-radius: 15px !important;
  border-top-left-radius: 15px !important;
  font-size: 13px;
  font-weight: 700;
}
.facts {
  gap: 15px;
  @media screen and (max-width: 600px) {
    margin-right: 10px;
  }
}
.scrolling-text {
  /* Add styles for the scrolling text if needed */
  font-weight: bold;
  white-space: wrap;
  color: #41b16d;
}
@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.scrolling-container {
  animation: fadeInOut 4s linear infinite;
}
</style>
